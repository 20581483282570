import { useState } from "react";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Product } from "../../admin/homeAdmin/HomeAdmin";
import Langu from "../../components/languge/languge";
import { useNavigate } from "react-router";

interface CardProductProp {
    Product: Product;
    dragging?: boolean
}

const CardProducts: React.FC<CardProductProp> = ({ Product, dragging }) => {
    const nav = useNavigate()
    const { t } = useTranslation()
    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null)
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
    const [colorActive, setColorActive] = useState<string>(Product?.colors?.[0]!)

    const onClickProduct = (event: React.MouseEvent) => {
        if ((event.target as HTMLElement).classList.contains("BtnBNB")) {
            return;
        }
        else if (!dragging) {
            nav(`/guest/market/productDetails/${Product?._id}`)
        }
    };

    return (<>
        <div
            onMouseEnter={() => setHoveredItemId(Product._id!)}
            onMouseLeave={() => setHoveredItemId(null)}
        >
            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                <div className="cardHome" style={{ borderRadius: "5px" }} onClick={onClickProduct}>
                    <img draggable={false} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                    <img draggable={false} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                </div>
                {
                    Product.offers &&
                    <div style={{ position: "absolute", top: Product.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {Product.discount! * -1} %
                    </div>
                }
                {
                    Product.disable &&
                    <div style={{ position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        {t("Market.Sold out")}
                    </div>
                }
                {
                    hoveredItemId === Product._id &&
                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {
                            <div className="BtnAddMarket" style={{ position: "absolute", top: "250px" }}>
                                <Button bsPrefix="BtnBNB" disabled={Product.disable} onClick={() => { setShowAlirtMsg(true) }} style={{ borderRadius: "20px" }}>{t("Market.add to cart")}</Button>
                            </div>
                        }
                    </div>
                }
                {Product.colors?.length! > 0 &&
                    <div style={{ display: "flex" }}>
                        {Product.colors?.map((color: string, index) => (
                            <Button
                                key={index}
                                onClick={() => setColorActive(color)}
                                style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive == color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                            </Button>
                        ))}
                    </div>
                }
                <div style={{ fontSize: "20px", padding: "5px" }}><Langu {...Product.product!} /></div>
                <div style={{ width: "100%", fontSize: "15px" }}>
                    {
                        Product.newPrice ?
                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {Product.price} ₪
                                </div>
                                <div style={{ color: "red" }}>
                                    {Product.newPrice} ₪
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "center", color: "gray" }}>
                                {Product.price} ₪
                            </div>
                    }
                </div>
            </div>
        </div>

        < Alert
            show={showAlirtMsg}
            variant={"danger"}
            style={{ position: "fixed", top: '10px', left: "30px", minWidth: "20%", zIndex: "10" }}
            onClose={() => setShowAlirtMsg(false)} dismissible
        >
            <Alert.Heading style={{ textAlign: "start" }}>
                {t("Market.Error message")}
            </Alert.Heading>
            <p>
                {t("Market.You are not registered, please log in to continue")}
            </p>
        </Alert >
    </>)
}

export default CardProducts;