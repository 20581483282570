import axios from "axios";
import { useEffect, useState } from "react";
import { UsersProps } from "../../admin/homeAdmin/HomeAdmin";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import ModaleChangePassword from "../../client/MyAccount/ModaleChangePassword";
import ModaleChangeEmail from "../../client/MyAccount/ModaleChangeEmail";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";



const AccountDetalsAdmin: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    let params = useParams()
    const [dataUser, setDataUser] = useState<UsersProps>()
    const [showModaleChangePassword, setShowModalChangePassword] = useState<boolean>(false)
    const [showModaleChangeEmail, setShowModalChangeEmail] = useState<boolean>(false)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${params.id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUser(doc.data))
            .catch((err) => console.log(err))
    }, [])

    return (<>
        <div style={{ backgroundColor: "gray", minHeight: "20vh", width: '100%' }}>
            <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Hedar.AccountDetals")}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", fontSize: "18px", }}>
            <div style={{ maxWidth: "1040px", width: "100%", padding: "20px", border: "1px solid #dddddd", borderRadius: "20px", margin: "50px", display: 'flex', flexDirection: 'column', gap: "5px" }}>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        {t("Home.name")} : {dataUser?.userName}
                    </div>
                    <div style={{ marginLeft: "50%" }}>
                        <Button bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        {t("Home.email")} : {dataUser?.email}
                    </div>
                    <div style={{ marginLeft: " 50%" }}>
                        <Button bsPrefix="BrandShoppBrand" onClick={() => setShowModalChangeEmail(true)} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        {t("Hedar.Password")} : *********
                    </div>
                    <div style={{ marginLeft: " 50%" }}>
                        <Button bsPrefix="BrandShoppBrand" onClick={() => setShowModalChangePassword(true)} style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
                    <div>
                        {t("Home.phone")} : {dataUser?.phone}
                    </div>
                    <div style={{ marginLeft: " 50%" }}>
                        <Button bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white", fontSize: "16px", color: 'blue' }}>
                            {t("Home.Account.change")}
                        </Button>
                    </div>
                </div>
            </div>
            <ModaleChangePassword show={showModaleChangePassword} setShowModalChangePassword={setShowModalChangePassword} userId={dataUser?._id!} />
            <ModaleChangeEmail show={showModaleChangeEmail} setShowModalChangeEmail={setShowModalChangeEmail} />
        </div>
    </>)
}

export default AccountDetalsAdmin;