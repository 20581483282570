import { Button, Form } from 'react-bootstrap';
import './HedarGuest.css'
import LoginModal from './LoginModal';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaRegUser } from "react-icons/fa";
import { IoSearch } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
import { GrFavorite } from "react-icons/gr";
import Search from './Search';
import ShoppingCart from './ShoppingCart';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useTranslation } from 'react-i18next';
import I18nGuest from './I18n.guest';



const HedarGuest: React.FC = () => {
    const { t } = useTranslation()

    const [showModalLogin, setShowModalLogin] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)
    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)

    const CarentItem = useSelector((state: RootState) => state.counterData)

    return (<>
        <nav>
            <div style={{ minHeight: "65px" }}>
                <div className="NavHedar">
                    <div style={{ maxWidth: "1440px", width: "1440px", padding: "0 40px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className='MenuNavLink' style={{ display: "flex", gap: "20px", flexDirection: "row-reverse" }}>
                                    <NavLink className={"NavLinkHedar"} to="/guest/category/Accessories and shawls">{t("Hedar.Accessories and shawls")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/guest/category/bags" >{t("Hedar.bags")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/guest/category/hours" >{t("Hedar.hours")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/guest/category/wallets" >{t("Hedar.wallets")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/guest/category/Hats and glasses">{t("Hedar.Hats and glasses")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/guest/homePage">{t("Home.home")}</NavLink>
                                    {/* <NavLink className={"NavLinkHedar"} to="/f" > حقائب للمناسبات </NavLink> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
                                <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                    <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white" }}>{CarentItem.value}</div>
                                    <MdOutlineShoppingCart className='IconHedar' size={"25px"} />
                                </Button>
                                <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px" }} onClick={() => { }}>
                                    <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", }}>{CarentItem.value}</div>
                                    <GrFavorite className='IconHedar' size={"25px"} />
                                </Button>
                                <Button bsPrefix='BtnBNBIcon' onClick={() => setShowModalLogin(true)}><FaRegUser className='IconHedar' size={"22px"} /></Button>
                                <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch className='IconHedar' size={"25px"} /> </Button>
                                <I18nGuest />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginModal show={showModalLogin} setShowModalLogin={setShowModalLogin} />
            <Search show={showSearch} setShowSearch={setShowSearch} />
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarGuest;