import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OrderProps } from "../../../../admin/market/orders/getOrders/GetOrders.admin";
import { useState } from "react";



interface dataOrders {
    dataOrder: OrderProps;
    onRefund: () => void;
}

const RefundCard: React.FC<dataOrders> = ({ dataOrder, onRefund }) => {
    const { t } = useTranslation()

    const [stautsBtn, setStatusBtn] = useState<any>(t("Refund Card"))
    const [disabledBtn, setDisabledBtn] = useState<boolean>(false)


    return (<>
        <Button variant="danger" disabled={disabledBtn}>{stautsBtn}</Button>
    </>)
}

export default RefundCard;