import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Category } from "./HomeAdmin";
import { Link, useNavigate } from "react-router-dom";
import { CloseButton } from "react-bootstrap";
import ModeleDeleteCategory from "../market/Modales/ModeleDeleteCategory";
import Langu from "../../components/languge/languge";
import { useTranslation } from "react-i18next";


const CategorysAdmin: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataCategorys, setDataCategorys] = useState<Category[]>()
    const [hoverCloseBtn, setHoverCloseBtn] = useState<string | null>(null)
    const [showModale, setShowModale] = useState<boolean>(false)
    const [idCategory, setIdCategory] = useState<string>('')
    const [dragging, setDragging] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const dragThreshold = 5;

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [showModale])

    const handelDeleteCategory = (id: string) => {
        setShowModale(true)
        setIdCategory(id)
    }

    const onMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true;
        startX.current = e.pageX - (scrollRef.current?.offsetLeft || 0);
        scrollLeft.current = scrollRef.current?.scrollLeft || 0;
        setDragging(true);
    };

    const onMouseLeaveOrUp = (e: React.MouseEvent) => {
        if (isDragging.current) {
            const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
            const distance = Math.abs(x - startX.current);
            if (distance < dragThreshold) {
                setDragging(false);
            }
        }
        isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollLeft.current - walk;
        }
    };

    const onClickCategory = (e: React.MouseEvent, category: Category) => {
        if (dragging) {
            e.preventDefault();
        } else {
            nav(`/admin/market/category/${category.name.en}`, { state: category.file?.[0] });
        }
    };
    return <>
        <div style={{ margin: "10px 0" }}>
            <div style={{ width: "100%", textAlign: "center", borderBottom: "1px solid #d1d1d1", padding: "15px 0", borderTop: "1px solid #d1d1d1" }}>
                <h2>{t("Home.Our varieties")}</h2>
            </div>
            <div
                ref={scrollRef}
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeaveOrUp}
                onMouseUp={onMouseLeaveOrUp}
                onMouseMove={onMouseMove}
                style={{ margin: "10px 0", display: "flex", overflowX: "auto", scrollbarWidth: "none", cursor: "grab" }}>
                {
                    dataCategorys?.map((Category, index) => (
                        <div key={index}
                            onMouseEnter={() => setHoverCloseBtn(Category._id)}
                            onMouseLeave={() => setHoverCloseBtn(null)}
                            style={{ position: "relative" }}
                        >
                            {
                                hoverCloseBtn === Category._id &&
                                <CloseButton
                                    style={{ position: "absolute", top: 0, left: 0, zIndex: 10 }}
                                    onClick={() => handelDeleteCategory(Category._id)}
                                />
                            }
                            <div onClick={(e) => onClickCategory(e, Category)} style={{ textDecoration: "none", color: "initial" }} key={index}>
                                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", margin: "0 10px", cursor: "pointer" }}>
                                    <img draggable={false} style={{ width: "300px", height: "300px", borderRadius: "50%", border: "1px solid #dddddd" }} src={`${process.env.REACT_APP_URL_API}/API/files/Categorys/${Category.file ? Category.file[0] : ""}`} />
                                    <div style={{ fontWeight: "500", marginTop: 10 }}><Langu {...Category.name} /></div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <ModeleDeleteCategory show={showModale} ModalDeleteCategory={setShowModale} id={idCategory} />
        </div>
    </>
}

export default CategorysAdmin;