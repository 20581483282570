import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import { useState } from "react";
import Langu from "../../../components/languge/languge";
import { useTranslation } from "react-i18next";
import ModelXLAdmin from "../../market/Modales/ModaleXL.admin";
import { Button } from "react-bootstrap";

interface dataProductsProps {
    Product: Product;
    dragging: boolean;
}

const AdminCardPeoduct: React.FC<dataProductsProps> = ({ Product, dragging }) => {
    const { t } = useTranslation()

    const [showModalXL, setShowModalXL] = useState<any>({
        setShow: false,
        data: {}
    })

    const onClickProduct = (event: React.MouseEvent, product: Product) => {
        if ((event.target as HTMLElement).classList.contains("BtnBNB")) {
            return;
        }
        else if (!dragging) {
            setShowModalXL({ setShow: true, data: product });
        }
    };
    return (<>

        <div
            onClick={(event) => onClickProduct(event, Product)}
        >
            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                <div className="cardHome" style={{ borderRadius: "5px" }}>
                    <img draggable={false} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                    <img draggable={false} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                </div>
                {
                    Product.offers &&
                    <div style={{ position: "absolute", top: Product.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {Product.discount! * -1} %
                    </div>
                }
                {
                    Product.disable &&
                    <div style={{ position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        {t("Market.Sold out")}
                    </div>
                }
                {Product.colors?.length! > 0 &&
                    <div style={{ display: "flex" }}>
                        {Product.colors?.map((color: string, index) => (
                            <Button
                                key={index}
                                style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px" }}>
                            </Button>
                        ))}
                    </div>
                }
                <div style={{ fontSize: "20px", padding: "5px" }}><Langu {...Product.product!} /></div>
                <div style={{ width: "100%", fontSize: "15px" }}>
                    {
                        Product.newPrice ?
                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {Product.price} ₪
                                </div>
                                <div style={{ color: "red" }}>
                                    {Product.newPrice} ₪
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "center", color: "gray" }}>
                                {Product.price} ₪
                            </div>
                    }
                </div>
            </div>
        </div>

        {
            showModalXL.setShow &&
            <ModelXLAdmin show={showModalXL.setShow} setShowModalXL={setShowModalXL} data={showModalXL.data} />
        }
    </>)
}

export default AdminCardPeoduct;