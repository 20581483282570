import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import { Brand, Category } from "../../homeAdmin/HomeAdmin";
import { useTranslation } from "react-i18next";
import Langu from "../../../components/languge/languge";
import BtnCreateProduct from "./BtnCreateProduct";


const CreateProduct: React.FC = () => {
    const { t } = useTranslation()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [dataCategory, setDataCategory] = useState<Category[]>()
    const [category, setCategory] = useState<string>("")
    const [brand, setBrand] = useState<string>('')
    const [productEN, setProductEN] = useState<string>('')
    const [productAR, setProductAR] = useState<string>('')
    const [productHE, setProductHE] = useState<string>('')
    const [informationEN, setInformationEN] = useState<string>('')
    const [informationAR, setInformationAR] = useState<string>('')
    const [informationHE, setInformationHE] = useState<string>('')
    const [color, setColor] = useState<string>()
    const [colorsPost, setColorsPost] = useState<(string | undefined)[]>([]);
    const [price, setPrice] = useState<number | undefined>()
    const [newPrice, setNewPrice] = useState<number | undefined>()
    const [number, setNumber] = useState<number | undefined>()
    const [photo, setPhoto] = useState<File[]>([])
    const [offerStatus, setOfferStatus] = useState<boolean>(false)
    const [hoveredItemId, setHoveredItemId] = useState<string | null | undefined>(null)
    const [targetStatus, setTargetStatus] = useState<boolean>(false)
    const [target, setTarget] = useState<string>("Men's")

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => { setDataCategory(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        category && axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => { setDataBrands(doc.data) })
            .catch((err) => console.log(err))
    }, [category])

    useEffect(() => {
        setTimeout(() => {
            if (dataCategory && dataCategory.length > 0) {
                setCategory(JSON.stringify({
                    en: dataCategory[0].name.en,
                    ar: dataCategory[0].name.ar,
                    he: dataCategory[0].name.he,
                }))
                setBrand(JSON.stringify({
                    en: dataBrands?.[0].name.en,
                    ar: dataBrands?.[0].name.ar,
                    he: dataBrands?.[0].name.he,
                }))
            }
        }, 500)
    }, [dataCategory])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }

    const discount: number | undefined = price && newPrice ? ((price - newPrice) / price) * 100 : undefined;

    const addColors = () => {
        setColorsPost(prevColors => [...prevColors, (color || '#000000')]);
    };

    const BtnDeleteColor = () => {
        setColorsPost(prevColors => prevColors.filter(c => c !== hoveredItemId));
    }

    const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTarget(e.target.value)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 30px 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Create Product")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", gap: "10px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Category name")} :</Form.Label>
                        <Form.Select onChange={e => { setCategory(e.target.value) }} value={category}>
                            {
                                dataCategory?.map((category, index) => (
                                    <option key={index} value={JSON.stringify({ en: category.name.en, ar: category.name.ar, he: category.name.he })}><Langu {...category.name} /></option>
                                ))
                            }
                            <option value={JSON.stringify({ en: "Packages", ar: "باكيجات", he: "باكيجات" })}>{t("Home.Packages")}</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Brand name")} :</Form.Label>
                        <Form.Select onChange={e => { setBrand(e.target.value) }} value={brand}>
                            {
                                dataBrands?.map((brands, index) => (
                                    <option key={index} value={JSON.stringify({ en: brands.name.en, ar: brands.name.ar, he: brands.name.he })}><Langu {...brands.name} /></option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Product name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setProductEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setProductAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="עִברִית" onChange={e => { setProductHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t("Market.information")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px" }}>
                        <Form.Control as="textarea" placeholder="English" onChange={e => { setInformationEN(e.target.value) }} />
                        <Form.Control as="textarea" placeholder="العربية" onChange={e => { setInformationAR(e.target.value) }} />
                        <Form.Control as="textarea" placeholder="עִברִית" onChange={e => { setInformationHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={t("Market.target")} style={{ margin: "20px 0" }} onChange={((e) => setTargetStatus(e.target.checked))} />
                    {targetStatus &&
                        <Form.Group style={{ marginBottom: 10 }}>
                            <label className="radio-label" htmlFor="Men's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Men's"
                                    name="target"
                                    value="Men's"
                                    checked={target === "Men's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Men's")}
                                <span className="radio-custom"></span>
                            </label> <label className="radio-label" htmlFor="Women's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Women's"
                                    name="target"
                                    value="Women's"
                                    checked={target === "Women's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Women's")}
                                <span className="radio-custom"></span>
                            </label>
                        </Form.Group>
                    }
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.colors" style={{ display: "flex", gap: "10px" }}>
                        <Form.Label>{t("colors")} :</Form.Label>
                        <Form.Control type="color" placeholder="" autoFocus onChange={e => { setColor(e.target.value) }} />
                        <Button bsPrefix="BtnBNB" onClick={addColors}>{t("add")}</Button>
                        {colorsPost?.map((co, index) => (
                            <div
                                key={index}
                                onMouseEnter={() => setHoveredItemId(co)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                style={{ backgroundColor: co, minWidth: 28, minHeight: 28, border: "1px solid #dddddd", margin: "10px 5px", cursor: "pointer" }}
                                onClick={BtnDeleteColor}
                            >
                            </div>
                        ))}
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={t("Market.offer?")} style={{ margin: "20px 0" }} onChange={((e) => setOfferStatus(e.target.checked))} />
                    {offerStatus ?
                        <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                            <Form.Label form="Price">{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} />
                            <Form.Label form="newPrice">{t("Market.newPrice")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setNewPrice(parseFloat(e.target.value) || undefined) }} />
                            <div style={{ width: "100%", padding: "10px" }}>{t("Market.discount")} : {discount?.toFixed(0)}%</div>
                        </Form.Group>
                        :
                        <Form.Group className="mb-3" controlId="exampleForm.Price">
                            <Form.Label>{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} />
                        </Form.Group>
                    }
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Price">
                        <Form.Label>{t("Market.Available number")} :</Form.Label>
                        <Form.Control type="number" placeholder="" onChange={e => { setNumber(parseFloat(e.target.value) || undefined) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Photos")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple />
                    </Form.Group>
                </div>
                <BtnCreateProduct brand={brand} category={category} colorsPost={colorsPost!} informationAR={informationAR} informationEN={informationEN} informationHE={informationHE}
                    newPrice={newPrice!} number={number!} offerStatus={offerStatus} photo={photo!} price={price!} productAR={productAR} productEN={productEN} productHE={productHE}
                    target={target} targetStatus={targetStatus} />
            </div>
        </div>
    </>)
}


export default CreateProduct;