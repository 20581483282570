import { useTranslation } from "react-i18next";
import { OrderProps } from "../../../../admin/market/orders/getOrders/GetOrders.admin";
import RefundCash from "./RefundCash";
import RefundCard from "./RefundCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

interface dataOrders {
    dataOrder: OrderProps;
    onRefund: () => void;
}

const Refund: React.FC<dataOrders> = ({ dataOrder, onRefund }) => {
    const { t } = useTranslation()
    const userData = useSelector((state: RootState) => state.userData);

    const status = () => {
        switch (dataOrder?.status) {
            case "new":
                return dataOrder?.paymentMethod === "cash" ?
                    <RefundCash dataOrder={dataOrder} onRefund={onRefund} />
                    :
                    <RefundCard dataOrder={dataOrder} onRefund={onRefund} />

            case "viewed":
                return dataOrder?.paymentMethod === "cash" ?
                    <RefundCash dataOrder={dataOrder} onRefund={onRefund} />
                    :
                    <RefundCard dataOrder={dataOrder} onRefund={onRefund} />
            case "delivery done":
                return null

            case "canceled":
                return t("Market.The order has been cancelled")

            default:
                if (userData.user.userDetals?.role === true) {
                    return dataOrder?.paymentMethod === "cash" ?
                        <RefundCash dataOrder={dataOrder} onRefund={onRefund} />
                        :
                        <RefundCard dataOrder={dataOrder} onRefund={onRefund} />
                }
                else {
                    return t("Market.You cannot cancel")
                }
        }
    }

    return (<>
        {status()}
    </>)
}

export default Refund;