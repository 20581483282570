import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { LoadingBtn } from "../../../components/laoding/laoding";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import GetOffersAndDelete from "./GetOffersAndDelete";





const CreateOffer: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const [statusBtnCreate, setStatusBtnCreate] = useState<any>(t("Market.Create Offer"))
    const [disabledBtnCreate, setDisabledBtnCreate] = useState<boolean>(false)
    const [nameOfferEN, setNameOfferEN] = useState<string>("")
    const [nameOfferAR, setNameOfferAR] = useState<string>("")
    const [nameOfferHE, setNameOfferHE] = useState<string>("")
    const [photo, setPhoto] = useState<File[]>([])
    const [errInput, setErrInput] = useState<string>('')

    useEffect(() => {
        setErrInput('')
    }, [nameOfferEN, nameOfferAR, nameOfferHE, photo])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }

    const BtnCreate = async () => {
        setStatusBtnCreate(<LoadingBtn />)
        setDisabledBtnCreate(true)
        if (!nameOfferEN || !nameOfferAR || !nameOfferHE) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Offer"))
            setErrInput(t("Market.Please enter data"))
            return;
        }
        if (photo.length == 0) {
            setDisabledBtnCreate(false)
            setStatusBtnCreate(t("Market.Create Offer"))
            setErrInput(t("Market.Please enter photo"))
            return;
        }

        const selectedFiles = []

        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        updatedFormData.append("nameOfferEN", nameOfferEN)
        updatedFormData.append("nameOfferAR", nameOfferAR)
        updatedFormData.append("nameOfferHE", nameOfferHE)
        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }

        await axios.post(`${process.env.REACT_APP_URL_API}/API/Offers/PostOffer`, updatedFormData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => console.log(doc))
            .catch(err => console.log(err))
        setStatusBtnCreate(t("Market.Create Offer"))
        setDisabledBtnCreate(false)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 0 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Create Offer")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Offer name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.name" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setNameOfferEN(e.target.value) }} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setNameOfferAR(e.target.value) }} />
                        <Form.Control type="text" placeholder="עִברִית" onChange={e => { setNameOfferHE(e.target.value) }} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Photo")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple />
                    </Form.Group>
                </div>

                <div>
                    <div style={{ color: "red" }}>{errInput}</div>
                    <Button bsPrefix="BtnBNB" onClick={BtnCreate} disabled={disabledBtnCreate}>{statusBtnCreate}</Button>
                </div>

                <GetOffersAndDelete refreshData={disabledBtnCreate} />
            </div>
        </div>
    </>)
}

export default CreateOffer;