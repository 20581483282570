import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import CartItemCompletePage from "../../../components/cartItem/CartItemCompletePage";
import { useTranslation } from "react-i18next";
import BasicExample from "./Accordion";




const CompleteOrder: React.FC = () => {
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const { t } = useTranslation()

    const subTotal: number = CarentItem.cartItems.reduce((total, cartitem) => {
        return total + (cartitem.data.newPrice || cartitem.data.price || 0) * cartitem.quantity
    }, 0)

    const Delivery = 20

    const Total: number = subTotal + Delivery

    return (<>
        <div style={{ display: "flex" }}>
            <div style={{ width: "50%" }}>
                {BasicExample()}
            </div>
            <div style={{ width: "50%", backgroundColor: "rgb(241 241 241)", minHeight: "100vh", padding: "0 20px" }}>
                <div style={{ position: "sticky", top: "75px", marginTop: "50px", width: "500px" }}>
                    <div style={{}}>
                        {CarentItem?.cartItems.map((item: any, index) => { return item ? <CartItemCompletePage key={index} data={item} /> : null }
                        )}
                    </div>
                    <div>
                        <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.Subtotal")}
                            </div>
                            <div>
                                {subTotal} ₪
                            </div>
                        </div>
                        <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.shipping")}
                            </div>
                            <div>
                                {Delivery} ₪
                            </div>
                        </div>
                        <div style={{ fontSize: "18px", fontWeight: "500", display: 'flex', width: "500px", justifyContent: "space-between" }}>
                            <div>
                                {t("Market.PayPage.Total")}
                            </div>
                            <div>
                                {Total} ₪
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default CompleteOrder;