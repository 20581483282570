import axios from "axios";
import { createContext, useEffect, useState, ReactNode, useCallback } from "react";
import { UsersProps } from "../../admin/homeAdmin/HomeAdmin";
import { io, Socket } from 'socket.io-client'
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";


interface Notification {
    chatId?: string;
    createdAt?: Date;
    senderId?: string;
    text?: string;
    role?: boolean;
    updatedAt?: Date;
    _id?: string;
    isRead?: boolean;
}
interface Chat {
    _id: string;
    members: string[];
    lastMessage: string;
}
interface currentChat {
    createdAt?: Date;
    members?: string[]
    updatedAt?: Date;
    _id?: string
}
interface messages {
    chatId?: string;
    createdAt?: Date;
    senderId?: string;
    text?: string;
    role: boolean;
    isRead: boolean;
    updatedAt?: Date;
    _id?: string
}
interface ChatContextType {
    userChats?: Chat[] | null;
    messages?: messages[] | null;
    isUserChatsLoading?: boolean;
    isMessagesLoading?: boolean;
    messagesError?: any;
    potentialChats?: any;
    createChat?: (firstId: string, secondId: string) => Promise<void>;
    UpDateCurrentChat?: ((chat: any) => Promise<void>) | undefined
    sendMessage?: (message: string, sender: any, currentChatId: string, setMessage: any) => Promise<void>
    currentChat?: currentChat;
    onlineUsers: any[];
    notification: Notification[];
    markAllNotificationAsRead: (notifications: Notification[]) => void;
    markNotifications: (
        n: messages,
        userChats: Chat[],
        user: UsersProps,
        notifications: Notification[]
    ) => void;
    allUsers?: UsersProps[];
    markThisUserNotificationsAsRead: (ThisUserNotifications: Notification[], notification: Notification[]) => void;
    newMessage: messages;
    notificationDB: messages[];
    isMobile: boolean;
    baseCurrency: number;
};

// نوع الـ props لـ ChatContextProvider
interface ChatContextProviderProps {
    children: ReactNode;
    user?: UsersProps;
}

// إنشاء الـ context مع قيمة افتراضية
export const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const ChatContextProvider = ({ children, user }: ChatContextProviderProps) => {
    const userData = useSelector((state: RootState) => state.userData)
    const [userChats, setUserChats] = useState<Chat[] | null>(null);
    const [messages, setMessages] = useState<messages[] | null>(null);
    const [isUserChatsLoading, setIsUserChatsLoading] = useState<boolean>(false);
    const [isMessagesLoading, setIsMessagesLoading] = useState<boolean>(false);
    const [messagesError, setMessagesError] = useState<any>(null);
    const [potentialChats, setpotenitialChats] = useState<UsersProps[]>()
    const [currentChat, setCurentChat] = useState<currentChat>()
    const [newMessage, setNewMessage] = useState<any>()
    const [socket, setSocket] = useState<Socket>()
    const [onlineUsers, setOnlineUsers] = useState<any>([])
    const [notification, setNotification] = useState<Notification[]>([])
    const [allUsers, setAllUsers] = useState<UsersProps[]>()
    const [notificationDB, setNotificationDB] = useState<messages[]>([])
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 500);
    const [baseCurrency, setBaseCurrency] = useState<number>(0)
    // if (!userData.user.token) { return; }


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 500);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const newSocket = io(userData?.user?.token && process.env.REACT_APP_URL_API)
        setSocket(newSocket)
        return () => { newSocket.disconnect() }
    }, [user])

    useEffect(() => {
        if (socket === null) return
        socket?.emit("addNewUser", user?.role ? true : user?._id)
        socket?.on("getOnlineUsers", (res: any) => {
            setOnlineUsers(res)
        })
        return () => { socket?.off("getOnlineUsers") }
    }, [socket])

    // send Message
    useEffect(() => {
        if (socket === null) return;
        const recipientId = user?.role ? currentChat?.members?.find((id: any) => id !== true) : currentChat?.members?.find((id) => id !== user?._id)

        socket?.emit("sendMessage", { ...newMessage, recipientId })

    }, [newMessage])

    // receicve Message and notification
    useEffect(() => {
        if (socket === null) return;

        socket?.on("getMessage", (res: messages) => {
            if (currentChat?._id !== res.chatId) return;
            setMessages((prev) => prev ? [...prev, res] : [res])
        })

        socket?.on("getNotification", async (res: any) => {
            const isChatOpen = currentChat?.members?.some(id =>
                id === res.senderId
            )
            if (isChatOpen) {
                setNotification((prev: any) => [{ ...res, isRead: true }, ...prev])
                await axios.put(`${process.env.REACT_APP_URL_API}/API/Message/IsReadMessage`, { id: res._id, isRead: true },
                    {
                        headers: {
                            Authorization: "Bearer " + userData.user.token,
                        }
                    }
                )
            } else {
                setNotification((prev: any) => [res, ...prev])
            }
        })
        return () => {
            socket?.off("getMessage")
            socket?.off("getNotification")
        }
    }, [socket, currentChat, user])

    useEffect(() => {
        // console.log(11)
        const isReadDB = async () => {
            user?._id && userData?.user?.token && await axios.get(`${process.env.REACT_APP_URL_API}/API/Message/Notification/${user?._id}`, {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            })
                .then((doc) => setNotificationDB(doc.data))
                .catch((err) => console.log(err))
        }
        isReadDB()
    }, [notification, socket])

    useEffect(() => {
        const getUserChats = async () => {
            if (user?._id) {
                await axios.get(`${process.env.REACT_APP_URL_API}/API/Chat/FindUserChats/${user?._id}`,
                    {
                        headers: {
                            Authorization: "Bearer " + userData.user.token,
                        }
                    }
                )
                    .then(async (doc) => {
                        setUserChats(doc.data)
                        if (doc.data.length === 0) {
                            await axios.post(`${process.env.REACT_APP_URL_API}/API/Chat/CreateChat`, user?.role ? { secondId: true, firstId: undefined } : { secondId: user?._id, firstId: true },
                                {
                                    headers: {
                                        Authorization: "Bearer " + userData.user.token,
                                    }
                                }
                            )
                                .then((doc) => { setUserChats((prev) => prev ? [...prev, doc.data] : [doc.data]); })
                                .catch((err) => console.log(err))
                        }
                    })
                    .catch((err) => console.log(err))
            }
        };
        getUserChats();
    }, [user, notification]);

    useEffect(() => {
        const getUsers = async () => {
            userData?.user?.token && await axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUserAdminChat`,
                {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    }
                }
            )
                .then((doc) => {
                    const pChats = doc.data.filter((userFilter: UsersProps) => {
                        let isChatCreated = false
                        if (user?._id === userFilter._id) return false
                        if (userChats) {
                            isChatCreated = userChats?.some((chat) => {
                                return chat.members[0] === userFilter._id || chat.members[1] === userFilter._id
                            })
                        }
                        return !isChatCreated
                    })
                    setpotenitialChats(pChats)
                    setAllUsers(doc.data)
                })
                .catch((err) => console.log(err))
        }
        getUsers()
    }, [userChats])



    useEffect(() => {
        const getMessages = async () => {

            setIsMessagesLoading(true);
            setMessagesError(null);

            try {
                const response = await axios.get(`${process.env.REACT_APP_URL_API}/API/Message/GetMessage/${currentChat?._id!}`,
                    {
                        headers: {
                            Authorization: "Bearer " + userData.user.token,
                        }
                    }
                );
                setIsMessagesLoading(false);

                if (response.status !== 200) {
                    return setMessagesError('Error fetching chats');
                }

                setMessages(response.data);
            } catch (error) {
                setIsMessagesLoading(false);

                // تحقق مما إذا كان error هو كائن Error
                if (error instanceof Error) {
                    setMessagesError(error.message);
                } else {
                    setMessagesError('An unknown error occurred');
                }
            }

        };
        userData?.user?.token && getMessages();
    }, [currentChat]);

    const UpDateCurrentChat = useCallback((chat: currentChat): Promise<void> => {
        setCurentChat(chat);
        return Promise.resolve();
    }, []);

    const createChat = useCallback(async (firstId: string, secondId: string) => {
        await axios.post(`${process.env.REACT_APP_URL_API}/API/Chat/CreateChat`, { firstId, secondId },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => { setUserChats((prev) => prev ? [...prev, doc.data] : [doc.data]); })
            .catch((err) => console.log(err))
    }, [])

    const sendMessage = useCallback(async (message: any, sender: any, currentChatId: string, setMessage: any) => {
        if (!message) return console.log("You must type something...")


        await axios.post(`${process.env.REACT_APP_URL_API}/API/Message/CreateMessage`,
            {
                chatId: currentChatId,
                senderId: sender?._id,
                role: user?.role,
                text: message
            },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => {
                setNewMessage(doc.data)
                setMessages((prev) => prev ? [...prev, doc.data] : [doc.data])
                setTimeout(() => {
                    setMessage("")
                }, 50)
            })
            .catch((err) => { })
    }, [])


    const markAllNotificationAsRead = useCallback((notifications: any[]) => {
        const mNotification = notifications.map((n: any) => {
            return { ...n, isRead: true }
        })
        setNotification(mNotification)
    }, [])

    const markNotifications = useCallback((
        n: messages,
        userChats: Chat[] | null | undefined,
        user: UsersProps,
        notifications: Notification[]
    ) => {
        const desiredChat = userChats?.find((chat) => {
            const chatMembers = [user._id, n.senderId]
            const isDesiredChat = chat?.members.every((member: any) => {
                return chatMembers.includes(member)
            })
            return isDesiredChat
        })

        const mNotification = notifications.map((el) => {
            if (n.senderId === el.senderId) {
                return { ...n, isRead: true }
            } else {
                return el
            }
        })
        UpDateCurrentChat(desiredChat!)
        setNotification(mNotification)
    }, [])

    const markThisUserNotificationsAsRead = useCallback((ThisUserNotifications: Notification[], notification: Notification[]) => {
        const mNotification = notification.map((el: Notification) => {
            // ابحث عن الإشعار الذي يتطابق مع senderId
            const matchedNotification = ThisUserNotifications.find(n => n.senderId === el.senderId);

            // إذا وجد تطابق، أعد تشكيل الإشعار ليصبح مقروءًا
            return matchedNotification ? { ...el, isRead: true } : el;
        });

        // تأكد من أن المصفوفة ليست undefined قبل التعيين
        setNotification(mNotification.filter(n => n !== undefined) as Notification[]);
    }, []);




    return (
        <ChatContext.Provider value={{
            userChats,
            messages,
            newMessage,
            isUserChatsLoading,
            isMessagesLoading,
            messagesError,
            potentialChats,
            createChat,
            UpDateCurrentChat,
            sendMessage,
            currentChat,
            onlineUsers,
            notification,
            allUsers,
            markAllNotificationAsRead,
            markNotifications,
            markThisUserNotificationsAsRead,
            notificationDB,
            isMobile,
            baseCurrency,
        }}>
            {children}
        </ChatContext.Provider>
    );
};
