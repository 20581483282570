import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/ChatContext";
import axios from "axios";
import { ChatProps } from "../../admin/homeAdmin/HomeAdmin";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

interface messages {
    chatId?: string;
    createdAt?: Date;
    senderId?: string;
    text?: string;
    updatedAt?: Date;
    _id?: string
}

export const UseFecrhLatesMessage = (chat: ChatProps) => {
    const userData = useSelector((state: RootState) => state.userData);
    const chatContext = useContext(ChatContext);
    const { notification, newMessage } = chatContext!
    const [latestMessage, setLatestMessage] = useState<messages>()

    useEffect(() => {
        const getMessages = async () => {
            await axios.get(`${process.env.REACT_APP_URL_API}/API/Message/GetMessage/${chat._id}`,
                {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    }
                }
            )
                .then((doc) => {
                    const lastMessage = doc.data[doc.data.length - 1]
                    setLatestMessage(lastMessage)
                })
                .catch((err) => console.log(err))
        }
        getMessages()
    }, [notification, newMessage])
    return { latestMessage }
}