import axios from "axios";
import { useEffect, useState } from "react";
import { Brand } from "../../homeAdmin/HomeAdmin";
import Langu from "../../../components/languge/languge";
import { Button, CloseButton, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

interface GetOffersAndDeleteProps {
    refreshData: boolean
}

const GetBrandsAndDelete: React.FC<GetOffersAndDeleteProps> = ({ refreshData }) => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const [brands, setBrands] = useState<Brand[]>()
    const [hoverCloseBtn, setHoverCloseBtn] = useState<string | null>(null)
    const [idDelet, setIdDelete] = useState<string>()
    const [showModale, setShowModale] = useState<boolean>(false)


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setBrands(doc.data))
            .catch((err) => console.log(err))
    }, [showModale, refreshData])

    const handelDeleteOffer = async (id: string) => {
        await axios.delete(`${process.env.REACT_APP_URL_API}/API/Brands/DeleteBrand/${id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData?.user?.token,
                }
            }
        )
            .then((doc) => console.log(doc.data))
            .catch((err) => console.log(err))
        setShowModale(false)
    }
    return (<>
        <div style={{ display: "flex", marginTop: 20 }}>
            {brands?.map((brand, index) => (
                <div
                    key={index}
                    onMouseEnter={() => setHoverCloseBtn(brand._id)}
                    onMouseLeave={() => setHoverCloseBtn(null)}
                    style={{ position: "relative" }}
                >
                    {
                        hoverCloseBtn === brand._id &&
                        <CloseButton
                            style={{ position: "absolute", top: 0, left: 0, zIndex: 10, color: "gray" }}
                            onClick={() => { setShowModale(true); setIdDelete(brand._id) }}
                        />
                    }
                    <div style={{ padding: 7, margin: 10, borderRadius: 4, borderBottom: hoverCloseBtn === brand._id ? "1px solid" : '' }}>
                        {<Langu {...brand.name} />}
                    </div>
                </div>
            ))}
        </div>
        <Modal show={showModale} onHide={() => setShowModale(false)}>
            <Modal.Header className="CloseBtn" closeButton>
                <Modal.Title>
                    {t("Home.Confirmation message")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t("Home.Do you really want to delete the category?")}
            </Modal.Body>
            <Modal.Footer style={{ alignItems: "center", justifyContent: "center" }}>
                <Button variant="secondary" onClick={() => setShowModale(false)}>{t("Hedar.Close")}</Button>
                <Button variant="danger" onClick={() => handelDeleteOffer(idDelet!)}>{t("Hedar.Delete")}</Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default GetBrandsAndDelete;