import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Brand, Product } from "../../admin/homeAdmin/HomeAdmin";
import Langu from "../../components/languge/languge";
import ClientCardPeoduct from "../components/CardsProduct/ClientCardPeoduct";
import { useTranslation } from "react-i18next";




const ShoppBrandClient: React.FC = () => {
    const { t } = useTranslation()
    const [dataCategorys, setDataCategorys] = useState<Brand[]>()
    const [dataBrand, setDataBrand] = useState<Brand | undefined>(dataCategorys ? dataCategorys[0] : undefined);
    const [dataProducts, setDataProducts] = useState<Product[]>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        setDataBrand(dataCategorys ? dataCategorys[0] : undefined)
    }, [dataCategorys])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProductsBrand/${dataBrand?.name.en}`)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
    }, [dataBrand])

    return (<>
        <div style={{ margin: ' 0 0 30px 0' }}>
            <div>
                <h2 style={{ width: "100%", display: "flex", justifyContent: "center", borderBottom: "1px solid #d1d1d1", borderTop: "1px solid #d1d1d1", padding: "15px 0" }}>{t("Home.Shop by brand")}</h2>
            </div>
            <div style={{ display: "flex", gap: "30px", justifyContent: "center" }}>
                {
                    dataCategorys?.map((brand, index) => <div key={index}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", minHeight: "50px", gap: "30px" }}>
                            {
                                brand === dataBrand ?
                                    <Button key={index} bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white", border: "1px solid #6f24d7", color: "#6f24d7" }} onClick={() => setDataBrand(brand)}><Langu {...brand.name} /></Button>
                                    :
                                    <Button key={index} bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white" }} onClick={() => setDataBrand(brand)}><Langu {...brand.name} /></Button>
                            }
                        </div>
                    </div>
                    )
                }
            </div>
            <div style={{ display: "flex", gap: "20px", marginTop: "30px", padding: "0 40px", flexWrap: "wrap", justifyContent: "center" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: 20, flexWrap: "wrap", justifyContent: "center" }}>
                    {
                        dataProducts?.length! > 0 ? dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 10).map((Product, index) => (
                            <ClientCardPeoduct Product={Product} dragging={false} key={index} />
                        ))
                            :
                            <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                {t("Market.There are no products with these specifications.")}
                            </div>
                    }
                </div>
            </div>

        </div>
    </>)
}
export default ShoppBrandClient;