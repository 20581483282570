import axios from "axios";
import { useEffect, useState } from "react";
import { Button, CloseButton, Form } from "react-bootstrap";
import { useParams } from "react-router";
import { Brand, Category, Product } from "../../homeAdmin/HomeAdmin";
import { useTranslation } from "react-i18next";
import { LoadingBtn } from "../../../components/laoding/laoding";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import Langu from "../../../components/languge/languge";


const UpdataProduct: React.FC = () => {
    let params = useParams()
    const { t, i18n } = useTranslation()
    let userData = useSelector((state: RootState) => state.userData)
    let token = userData.user.token
    const [statusBtnUpdate, setStatusBtnUpdate] = useState<any>(t("Market.Update Product"))
    const [disabledBtnUpdate, setDisabledBtnUpdate] = useState<boolean>(false)
    const [dataCategory, setDataCategory] = useState<Category[]>()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [dataProduct, setDataProduct] = useState<Product>()
    const [category, setCategory] = useState<string>(JSON.stringify({ en: dataProduct?.category?.en, ar: dataProduct?.category?.ar, he: dataProduct?.category?.he }))
    const [brand, setBrand] = useState<string>(JSON.stringify({ en: dataProduct?.brand?.en, ar: dataProduct?.brand?.ar, he: dataProduct?.brand?.he }))
    const [productEN, setProductEN] = useState<string>('')
    const [productAR, setProductAR] = useState<string>('')
    const [productHE, setProductHE] = useState<string>('')
    const [informationEN, setInformationEN] = useState<string>('')
    const [informationAR, setInformationAR] = useState<string>('')
    const [informationHE, setInformationHE] = useState<string>('')
    const [color, setColor] = useState<string>()
    const [colorsPost, setColorsPost] = useState<(string | undefined)[]>(dataProduct ? dataProduct?.colors! : []);
    const [price, setPrice] = useState<number | undefined>()
    const [newPrice, setNewPrice] = useState<number | undefined>()
    const [number, setNumber] = useState<number | undefined>()
    const [photo, setPhoto] = useState<File[]>([])
    const [errInput, setErrInput] = useState<string>('')
    const [offerStatus, setOfferStatus] = useState<boolean>(false)
    const [hoveredItemId, setHoveredItemId] = useState<string | null | undefined>(null)
    const [targetStatus, setTargetStatus] = useState<boolean>(false)
    const [target, setTarget] = useState<string>("Men's")

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetCategorys`)
            .then((doc) => {
                setDataCategory(doc.data)
                if (doc.data && doc.data.length > 0) {
                    setCategory(JSON.stringify({
                        en: doc.data[0].name.en,
                        ar: doc.data[0].name.ar,
                        he: doc.data[0].name.he
                    }))
                }
            })
            .catch((err) => console.log(err))

        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => { setDataBrands(doc.data) })
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setColorsPost(dataProduct?.colors!)
        }, 300)
    }, [dataProduct])

    useEffect(() => {
        if (dataProduct) {

            const timer = setTimeout(() => {
                setCategory(JSON.stringify({
                    en: dataProduct?.category?.en || dataCategory?.[0].name?.en,
                    ar: dataProduct?.category?.ar || dataCategory?.[0].name?.ar,
                    he: dataProduct?.category?.he || dataCategory?.[0].name?.he,
                }));
                setBrand(JSON.stringify({
                    en: dataProduct?.brand?.en || dataBrands?.[0].name?.en,
                    ar: dataProduct?.brand?.ar || dataBrands?.[0].name?.ar,
                    he: dataProduct?.brand?.he || dataBrands?.[0].name?.he
                }));
                setTarget(dataProduct?.target || "Men's")
            }, 200);

            setProductEN(dataProduct?.product?.en || "");
            setProductAR(dataProduct?.product?.ar || "");
            setProductHE(dataProduct?.product?.he || "");

            setInformationEN(dataProduct?.information?.en || "");
            setInformationAR(dataProduct?.information?.ar || "");
            setInformationHE(dataProduct?.information?.he || "");

            setPrice(dataProduct?.price || 0);
            setNewPrice(dataProduct?.newPrice || 0);
            setNumber(dataProduct?.number || 0);

            return () => clearTimeout(timer);
        }
    }, [dataProduct]);


    useEffect(() => {
        setErrInput('')
    }, [productEN, productAR, productHE, informationEN, informationAR, informationHE, price, number])

    const handelPhoto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setPhoto(Array.from(files))
        }
    }


    const BtnUpdate = async () => {
        setStatusBtnUpdate(<LoadingBtn />)
        setDisabledBtnUpdate(true)
        if (!productEN || !productAR || !productHE || !informationEN || !informationAR || !informationHE || !price || !number) {
            setDisabledBtnUpdate(false)
            setStatusBtnUpdate("Market.Update Product")
            setErrInput(t('Market.Please enter data'))
            return;
        }

        const Category = JSON.parse(category)
        const Brand = JSON.parse(brand)

        const selectedFiles = []
        for (let i = 0; i < photo.length; i++) {
            selectedFiles.push(photo[i]);
        }
        const updatedFormData = new FormData();
        updatedFormData.append("categoryEN", Category.en)
        updatedFormData.append("categoryAR", Category.ar)
        updatedFormData.append("categoryHE", Category.he)
        updatedFormData.append("brandEN", Brand.en)
        updatedFormData.append("brandAR", Brand.ar)
        updatedFormData.append("brandHE", Brand.he)
        updatedFormData.append("productEN", productEN)
        updatedFormData.append("productAR", productAR)
        updatedFormData.append("productHE", productHE)
        updatedFormData.append("informationEN", informationEN)
        updatedFormData.append("informationAR", informationAR)
        updatedFormData.append("informationHE", informationHE)
        updatedFormData.append("colors", JSON.stringify(colorsPost))
        if (price !== undefined) {
            if (offerStatus && newPrice !== undefined) {
                updatedFormData.append("price", price.toString());
                updatedFormData.append("newPrice", newPrice.toString());
                updatedFormData.append("offers", offerStatus.toString());
            }
            else {
                updatedFormData.append("price", price.toString());
            }
        }

        if (targetStatus) {
            updatedFormData.append("target", target)
        }

        if (number !== undefined) {
            updatedFormData.append("number", number.toString());
        }

        for (let i = 0; i < selectedFiles.length; i++) {
            updatedFormData.append("file", selectedFiles[i]);
        }
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutProduct/${params.id}`, updatedFormData, {
            headers: {
                Authorization: "Bearar " + token,
                "Content-Type": "multipart/form-data"
            }
        })
            .then((doc) => console.log(doc.data))
            .catch((err) => console.log(err))
        setStatusBtnUpdate(t("Market.Update Product"))
        setDisabledBtnUpdate(false)
    }

    const addColors = () => {
        if (color) {
            setColorsPost(prevColors => [...prevColors, (color || "#000000")]);
        }
    };

    const BtnDeleteColor = () => {
        setColorsPost(prevColors => prevColors.filter(c => c !== hoveredItemId));
    }

    const handleChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTarget(e.target.value)
    }

    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%", boxShadow: "rgb(214, 214, 214) 5px 6px 10px", padding: "30px", borderRadius: "10px", border: "solid 1px #d6d6d6", margin: "20px 10% 30px 10%" }}>
                <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Update Product")}</h1>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", gap: "10px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Category" style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Category name")} :</Form.Label>
                        <Form.Select onChange={e => { setCategory(e.target.value) }} value={category} defaultValue={i18n.language == "ar" ? dataProduct?.category?.ar : i18n.language == "en" ? dataProduct?.category?.en : dataProduct?.category?.he}>
                            {
                                dataCategory?.map((category, index) => (
                                    <option key={index} value={JSON.stringify({ en: category.name.en, ar: category.name.ar, he: category.name.he })}><Langu {...category.name} /></option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                    <Form.Group style={{ width: "49%" }}>
                        <Form.Label>{t("Market.Brand name")} :</Form.Label>
                        <Form.Select onChange={e => { setBrand(e.target.value) }} value={brand}>
                            {
                                dataBrands?.map((brands, index) => (
                                    <option key={index} value={JSON.stringify({ en: brands.name.en, ar: brands.name.ar, he: brands.name.he })}><Langu {...brands.name} /></option>
                                ))
                            }
                        </Form.Select>
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Label>{t("Market.Product name")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px" }}>
                        <Form.Control type="text" placeholder="English" autoFocus onChange={e => { setProductEN(e.target.value) }} defaultValue={productEN} />
                        <Form.Control type="text" placeholder="العربية" onChange={e => { setProductAR(e.target.value) }} defaultValue={productAR} />
                        <Form.Control type="text" placeholder="עִברִית" onChange={e => { setProductHE(e.target.value) }} defaultValue={productHE} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                    <Form.Label>{t("Market.information")} :</Form.Label>
                    <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px" }}>
                        <Form.Control as="textarea" placeholder="English" onChange={e => { setInformationEN(e.target.value) }} defaultValue={informationEN} />
                        <Form.Control as="textarea" placeholder="العربية" onChange={e => { setInformationAR(e.target.value) }} defaultValue={informationAR} />
                        <Form.Control as="textarea" placeholder="עִברִית" onChange={e => { setInformationHE(e.target.value) }} defaultValue={informationHE} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={t("Market.target")} style={{ margin: "20px 0" }} onChange={((e) => setTargetStatus(e.target.checked))} />
                    {targetStatus &&
                        <Form.Group style={{ marginBottom: 10 }}>
                            <label className="radio-label" htmlFor="Men's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Men's"
                                    name="target"
                                    value="Men's"
                                    checked={target === "Men's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Men's")}
                                <span className="radio-custom"></span>
                            </label> <label className="radio-label" htmlFor="Women's" style={{ display: "flex", gap: "5px", color: "black" }}>
                                <input
                                    type="radio"
                                    id="Women's"
                                    name="target"
                                    value="Women's"
                                    checked={target === "Women's"}
                                    onChange={handleChangeRadio}
                                />
                                {t("Market.Women's")}
                                <span className="radio-custom"></span>
                            </label>
                        </Form.Group>
                    }
                </div>
                <div style={{ width: "100%", maxWidth: "1000px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.colors" style={{ display: "flex", gap: "10px" }}>
                        <Form.Label>{t("colors")} :</Form.Label>
                        <Form.Control type="color" placeholder="" autoFocus onChange={e => { setColor(e.target.value) }} />
                        <Button bsPrefix="BtnBNB" onClick={addColors}>{t("add")}</Button>
                        {colorsPost?.map((co, index) => (
                            <div
                                key={index}
                                onMouseEnter={() => setHoveredItemId(co)}
                                onMouseLeave={() => setHoveredItemId(null)}
                                style={{ backgroundColor: co, minWidth: 28, minHeight: 28, border: "1px solid #dddddd", margin: "10px 5px", cursor: "pointer" }}
                                onClick={BtnDeleteColor}
                            >
                            </div>
                        ))}
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Check type="switch" label={t("Market.offer?")} style={{ margin: "20px 0" }} onChange={((e) => setOfferStatus(e.target.checked))} />
                    {offerStatus ?
                        <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                            <Form.Label form="Price">{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" id="Price" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} defaultValue={price} />
                            <Form.Label form="newPrice">{t("Market.newPrice")} :</Form.Label>
                            <Form.Control type="number" placeholder="" id="newPrice" onChange={e => { setNewPrice(parseFloat(e.target.value) || undefined) }} />
                        </Form.Group>
                        :
                        <Form.Group className="mb-3" controlId="exampleForm.Price">
                            <Form.Label>{t("Market.price")} :</Form.Label>
                            <Form.Control type="number" placeholder="" onChange={e => { setPrice(parseFloat(e.target.value) || undefined) }} defaultValue={price} />
                        </Form.Group>
                    }
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Price">
                        <Form.Label>{t("Market.Available number")} :</Form.Label>
                        <Form.Control type="number" placeholder="" onChange={e => { setNumber(parseFloat(e.target.value) || undefined) }} defaultValue={number} />
                    </Form.Group>
                </div>
                <div style={{ width: "100%", maxWidth: "700px" }}>
                    <Form.Group className="mb-3" controlId="exampleForm.Photos">
                        <Form.Label>{t("Market.Photos")} :</Form.Label>
                        <Form.Control type="file" onChange={handelPhoto} multiple />
                    </Form.Group>
                </div>
                <div>
                    <div style={{ color: "red" }}>{errInput}</div>
                    <Button bsPrefix="BtnBNB" onClick={BtnUpdate} disabled={disabledBtnUpdate} >{statusBtnUpdate}</Button>
                </div>
            </div>
        </div>
    </>)
}

export default UpdataProduct;