import { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import PaymentMethods from './PaymentMethods';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { UsersProps } from '../../../admin/homeAdmin/HomeAdmin';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';

function BasicExample() {
    const { t, i18n } = useTranslation()
    const accordionRef = useRef<HTMLDivElement>(null);
    const userData = useSelector((state: RootState) => state.userData)

    const [dataUserId, setDataUserId] = useState<UsersProps>()
    const [country, setCountry] = useState<string | undefined>(dataUserId ? dataUserId?.address?.country : "")
    const [neighborhood, setNeighborhood] = useState<string | undefined>(dataUserId ? dataUserId?.address?.neighborhood : "")
    const [street, setStreet] = useState<string | undefined>(dataUserId ? dataUserId?.address?.street : "")
    const [buildingNumber, setBuildingNumber] = useState<string | number | undefined>(dataUserId ? dataUserId?.address?.buildingNumber : "")
    const [floor, setFloor] = useState<number | undefined>(dataUserId ? dataUserId?.address?.floor : 0)
    const [apartmentNo, setApartmentNo] = useState<number | undefined>(dataUserId ? dataUserId?.address?.apartmentNo : 0)
    const [status, setStatus] = useState<boolean>(false)
    const [activeKey, setActiveKey] = useState<string | null>("0");

    useEffect(() => {
        setCountry(dataUserId ? dataUserId?.address?.country : "")
        setNeighborhood(dataUserId ? dataUserId?.address?.neighborhood : "")
        setStreet(dataUserId ? dataUserId?.address?.street : "")
        setBuildingNumber(dataUserId ? dataUserId?.address?.buildingNumber : "")
        setFloor(dataUserId ? dataUserId?.address?.floor : 0)
        setApartmentNo(dataUserId ? dataUserId?.address?.apartmentNo : 0)
    }, [dataUserId])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUser/${userData?.user?.userDetals?._id}`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUserId(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        const isRtl = i18n.language === 'ar' || i18n.language === 'he';

        if (accordionRef.current) {
            const headers = accordionRef.current.querySelectorAll('.accordion-header');

            headers.forEach((header) => {
                const button = header.querySelector('.accordion-button') as HTMLElement;
                if (button) {
                    button.style.flexDirection = isRtl ? 'row-reverse' : 'row';
                }
            });
        }
    }, [i18n.language])

    const dataPutAddress = { address: { country, neighborhood, street, buildingNumber, floor, apartmentNo } }
    const putAddressUser = async () => {
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Users/PutUserId/${userData?.user?.userDetals?._id}`,
            dataPutAddress,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then(() => {
                setStatus(true)
                setActiveKey("1");
            })
            .catch((err) => console.log(err))
    }

    const handleSelect = (key: AccordionEventKey) => {
        if (typeof key === 'string') {
            setActiveKey(key);
        }
    };

    return (
        <Accordion activeKey={activeKey} defaultActiveKey="0" ref={accordionRef} onSelect={handleSelect}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>{t("Market.PayPage.addres")} </Accordion.Header>
                <Accordion.Body>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", borderRadius: "10px" }}>
                        {/* <h1 style={{ width: "100%", borderBottom: "1px solid rgb(214, 214, 214)", textAlign: "center", paddingBottom: "20px" }}>{t("Market.Create Product")}</h1> */}
                        <div style={{ width: "100%", maxWidth: "1000px" }}>
                            <Form.Label>{t("Market.PayPage.Country")} :</Form.Label>
                            <Form.Group className="mb-3" controlId="exampleForm.Product" style={{ display: "flex", gap: "10px", width: "50%" }}>
                                <Form.Control type="text" placeholder="" autoFocus onChange={(e) => setCountry(e.target.value)} defaultValue={dataUserId?.address?.country} />
                            </Form.Group>
                        </div>
                        <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                            <Form.Label>{t("Market.PayPage.Neighborhood")} :</Form.Label>
                            <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                                <Form.Control type="text" placeholder="" onChange={(e) => setNeighborhood(e.target.value)} defaultValue={dataUserId?.address?.neighborhood} />
                            </Form.Group>
                        </div>

                        <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                            <Form.Label>{t("Market.PayPage.Street")} :</Form.Label>
                            <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                                <Form.Control type="text" placeholder="" onChange={(e) => setStreet(e.target.value)} defaultValue={dataUserId?.address?.street} />
                            </Form.Group>
                        </div>
                        <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                            <Form.Label> {t("Market.PayPage.Building number")}:</Form.Label>
                            <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                                <Form.Control type="text" placeholder="" onChange={(e) => setBuildingNumber(e.target.value)} defaultValue={dataUserId?.address?.buildingNumber} />
                            </Form.Group>
                        </div>
                        <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                            <Form.Label>{t('Market.PayPage.floor')} :</Form.Label>
                            <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                                <Form.Control type="number" placeholder="" onChange={(e) => setFloor(parseInt(e.target.value))} defaultValue={dataUserId?.address?.floor} />
                            </Form.Group>
                        </div>
                        <div style={{ width: "100%", maxWidth: "1000px", display: "flex", flexDirection: "column" }}>
                            <Form.Label> {t("Market.PayPage.Apartment No")} :</Form.Label>
                            <Form.Group className="mb-3" controlId="exampleForm.Information" style={{ display: "grid", gap: "10px", width: "50%" }}>
                                <Form.Control type="number" placeholder="" onChange={(e) => setApartmentNo(parseInt(e.target.value))} defaultValue={dataUserId?.address?.apartmentNo} />
                            </Form.Group>
                        </div>
                        <div>
                            <Button bsPrefix="BtnBNB" onClick={putAddressUser}>{t("Market.PayPage.confirm")}</Button>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" >
                <Accordion.Header style={{ pointerEvents: status ? 'auto' : 'none', opacity: status ? 1 : 0.5 }}>{t("Market.PayPage.Payment methods")}</Accordion.Header>
                <Accordion.Body>
                    <PaymentMethods dataPutAddress={dataPutAddress!} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default BasicExample;