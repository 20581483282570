import axios from "axios"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form } from "react-bootstrap"
import Langu from "../../../../components/languge/languge"
import AdminCardPeoduct from "../../../components/CardsProducts/AdminCardProduct"
import AdminYouMayAlsoLike from "../../../components/CardsProducts/AdminYouMayAlsoLike"
import { Brand, Product, ProductSearch } from "../../../homeAdmin/HomeAdmin"

const GetProduct1Admin = () => {
    const { t } = useTranslation()
    const [price, setPrice] = useState<string>("All")
    const [dataProducts, setDataProducts] = useState<ProductSearch>()
    const [dataBrands, setDataBrands] = useState<Brand[]>()
    const [checkBrand, setCheckBrand] = useState<string[]>([])
    const [dataNewProducts, setDataNewProducts] = useState<Product[]>()
    const [checkTarget, setCheckTarget] = useState<string>("Alll")

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`)
            .then((doc) => setDataBrands(doc.data))
            .catch((err) => console.log(err))
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts`)
            .then((doc) => setDataNewProducts(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const dataSearch = { price, brand: checkBrand, offers: true, target: checkTarget }

    useEffect(() => {
        axios.put(`${process.env.REACT_APP_URL_API}/API/Products/PutSearchProducts`, dataSearch)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
    }, [price, checkBrand, checkTarget])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrice(e.target.value);
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckBrand([...checkBrand!, value]);
        } else {
            setCheckBrand(checkBrand!.filter((brand) => brand !== value));
        }
    };
    const handleCheckboxChangeTarget = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCheckTarget(e.target.value);
    };
    return (<>
        <div>
            <div style={{ backgroundColor: "gray", minHeight: "20vh", width: "100%" }}>
                <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Home.offer1")}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "20%", height: "100%", maxWidth: "250px", border: "1px solid #dddddd", margin: "50px 20px 50px 0", padding: "10px" }}>
                    <div style={{ minHeight: '30vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Home.You may also like")}</div>
                        <div>
                            <AdminYouMayAlsoLike dataProducts={dataNewProducts!} />
                        </div>
                    </div>
                    <div style={{ minHeight: '10vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.price")}</div>
                        <div>
                            <Form.Group className="mb-3" controlId="exampleForm.newPrice">
                                <div className="radio-container" style={{ borderBottom: "1px solid #dddddd", paddingBottom: "10px", display: "flex", gap: "30px", flexDirection: "column" }}>
                                    {["All", "0-49", "50-99", "100-299"].map((pricee, index) => (
                                        <label key={index} className="radio-label" htmlFor={`${pricee}`} style={{ display: "flex", gap: "5px" }}>
                                            <input
                                                type="radio"
                                                id={`${pricee}`}
                                                name="priceFiltar"
                                                value={`${pricee}`}
                                                checked={price === `${pricee}`}
                                                onChange={handleChange}
                                            />
                                            {`${pricee}`}
                                            <span className="radio-custom"></span>
                                        </label>
                                    ))}
                                    <label className="radio-label" htmlFor="300" style={{ display: "flex", gap: "5px" }}>
                                        <input
                                            type="radio"
                                            id="300"
                                            name="priceFiltar"
                                            value="300"
                                            checked={price === "300"}
                                            onChange={handleChange}
                                        />
                                        {t("Market.more than 300")}
                                        <span className="radio-custom"></span>
                                    </label>
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div style={{ minHeight: '20vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.brand")}</div>
                        <div>
                            <Form style={{ display: "flex", flexDirection: 'column', gap: '10px' }}>
                                {
                                    dataBrands?.map((brand, index) => (
                                        <Form.Check
                                            key={index}
                                            type="checkbox"
                                            id={brand.name.en}
                                            label={<Langu {...brand.name} />}
                                            value={brand.name.en}
                                            onChange={handleCheckboxChange}
                                        />
                                    ))
                                }
                            </Form>
                        </div>
                    </div>
                    <div style={{ minHeight: '20vh' }}>
                        <div style={{ width: "100%", textAlign: "center", fontSize: "20px" }}>{t("Market.target")}</div>
                        <div className="radio-container" style={{ paddingBottom: "10px", display: "flex", gap: "20px", flexDirection: "column" }}>
                            {["Alll", "Men's", "Women's"].map((target) => (
                                <label key={`target-${target}`} className="radio-label" htmlFor={`${target}`} style={{ display: "flex", gap: "5px" }}>
                                    <input
                                        type="radio"
                                        id={`${target}`}
                                        name="target"
                                        value={`${target}`}
                                        checked={checkTarget === `${target}`}
                                        onChange={handleCheckboxChangeTarget}
                                    />
                                    {t(`Market.${target}`)}
                                    <span className="radio-custom"></span>
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: "1440px", width: "100%" }}>
                    <div style={{ display: "flex", gap: "20px", marginTop: "30px", padding: "0 40px", flexWrap: "wrap", justifyContent: "center", maxWidth: "1440px", width: "100%", alignContent: "start" }}>

                        {
                            dataProducts?.movies?.length! > 0 ? dataProducts?.movies?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).map((Product, index) => (
                                <AdminCardPeoduct Product={Product} dragging={false} key={index} />
                            ))
                                :
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                    {t("Market.There are no products with these specifications.")}
                                </div>
                        }
                    </div>
                </div>
            </div >
        </div >
    </>)
}


export default GetProduct1Admin;