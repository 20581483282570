import { Button, Offcanvas } from "react-bootstrap";
import { TbShoppingCartX } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CartItem from "../../components/cartItem/CartItem";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

interface ShoppingCartProp {
    show: boolean;
    setShowShoppingCart: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShoppingCart: React.FC<ShoppingCartProp> = ({ show, setShowShoppingCart }) => {
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const { t } = useTranslation()
    const nav = useNavigate()

    const subTotal: number = CarentItem.cartItems.reduce((total, cartitem) => {
        return total + (cartitem.data.newPrice || cartitem.data.price || 0) * cartitem.quantity
    }, 0)

    const navPayPage = () => {
        setShowShoppingCart(false)
        nav('/client/market/payPage', { state: subTotal })
    }

    return (<>
        <Offcanvas show={show} onHide={() => setShowShoppingCart(false)} style={{ maxWidth: "350px" }}>
            <Offcanvas.Header className="CloseBtn" style={{ borderBottom: "1px solid #e1e1e1" }} closeButton>
                <Offcanvas.Title>{t("Market.PayPage.Shopping Cart")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", height: "100%", flexDirection: "column" }}>
                {
                    CarentItem.cartItems.length > 0 ?
                        <>
                            <div style={{ width: "100%", maxHeight: "75vh", overflowY: "auto" }}>
                                {CarentItem?.cartItems.map((item: any, index) => { return item ? <CartItem key={index} data={item} /> : null }
                                )}
                            </div>
                            <div style={{ position: "absolute", bottom: "20px", width: "100%", textAlign: "center", boxShadow: "#e1e1e1 -1px -5px 5px", minHeight: "12vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "20px" }}>
                                <div style={{ fontSize: "20px", fontWeight: "500" }}>{t("Market.PayPage.Subtotal")} : {subTotal} ₪</div>
                                <Button bsPrefix="BtnBNB" onClick={navPayPage} style={{ width: "80%" }}>{t("Market.PayPage.View the cart")}</Button>
                            </div>
                        </>
                        :
                        <>
                            <TbShoppingCartX size={"100px"} />
                            <Offcanvas.Title>{t("Market.PayPage.Your shopping cart is empty")}</Offcanvas.Title>
                        </>
                }
            </Offcanvas.Body>
        </Offcanvas>
    </>)
}

export default ShoppingCart;