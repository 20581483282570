import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { OrderProps } from "../../admin/market/orders/getOrders/GetOrders.admin";
import { Button, Table } from "react-bootstrap";
import Langu from "../../components/languge/languge";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";



const MyOrders: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataOrders, setDataOrders] = useState<OrderProps[]>()
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrdersUserId/${userData?.user?.userDetals?._id}/1000`, {
            headers: {
                Authorization: "Bearer " + userData.user.token,
            }
        })
            .then((doc) => setDataOrders(doc.data))
            .catch((err) => console.log(err))
    }, [])

    return (<>
        <div style={{
            // backgroundImage: dataCategory && `url(${process.env.REACT_APP_URL_API}/API/files/Categorys/${dataCategory?.file?.[0]})`,
            minHeight: "20vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            backgroundBlendMode: "darken",
        }}>
            <div className="nameOffer" style={{ top: "calc(10vh + 60px)" }}>{t("Hedar.my Orders")}</div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: "1440px", width: "100%", padding: "0 40px" }}>
                <Table striped style={{ border: "1px solid #dddddd" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t("Market.product")}</th>
                            <th>{t("Market.Total price")}</th>
                            <th>{t("Market.PayPage.Payment methods")}</th>
                            <th>{t("Market.Timing")}</th>
                            <th>{t("Market.status")}</th>
                            {/* <th>{t("Home.active")}</th> */}
                            <th style={{ width: "15%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataOrders?.map((order, index) =>
                                <tr key={index} >
                                    <td style={{ alignContent: "center", fontWeight: "500" }}>{index + 1}</td>
                                    <td style={{ alignContent: "center", fontWeight: "500" }}>
                                        <div >
                                            {order.dataItems?.map((item, index) =>
                                                <div key={index}>
                                                    <Langu  {...item.product!} /> × {item.quantity}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td style={{ alignContent: "center", fontWeight: "500" }}>{order.totalPrice} ₪</td>
                                    <td style={{ alignContent: "center", fontWeight: "500" }}>{t(`Market.PayPage.${order.paymentMethod}`)}</td>
                                    <td style={{ alignContent: "center", fontWeight: "500" }}>{new Date(order?.createdAt!).toLocaleString('en-GB', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    })}</td>
                                    <td style={{ alignContent: "center", fontWeight: "500" }}>{t(`Market.PayPage.${order.status}`)}</td>
                                    <td style={{ width: "15%", alignContent: "center" }}><Button bsPrefix="BtnBNB" onClick={() => nav(`/client/myAccount/orderDetals/${order._id}`)}>{t("Market.Order details")}</Button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </div >

    </>)
}

export default MyOrders;