import { useEffect, useRef, useState } from "react";
import { Product } from "./HomeAdmin";
import axios from "axios";
import AdminCardPeoduct from "../components/CardsProducts/AdminCardProduct";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";


const PackagesAdmin: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const scrollRef = useRef<HTMLDivElement>(null);
    const isDragging = useRef(false);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const dragThreshold = 5;
    const [dragging, setDragging] = useState(false);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProducts/Packages`)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
    }, [])

    const onMouseDown = (e: React.MouseEvent) => {
        isDragging.current = true;
        startX.current = e.pageX - (scrollRef.current?.offsetLeft || 0);
        scrollLeft.current = scrollRef.current?.scrollLeft || 0;
        setDragging(false);
    };

    const onMouseLeaveOrUp = (e: React.MouseEvent) => {
        if (isDragging.current) {
            const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
            const distance = Math.abs(x - startX.current);
            if (distance > dragThreshold) {
                setDragging(true);
            }
        }
        isDragging.current = false;
    };

    const onMouseMove = (e: React.MouseEvent) => {
        if (!isDragging.current) return;
        e.preventDefault();
        const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
        const walk = (x - startX.current) * 1;
        if (scrollRef.current) {
            scrollRef.current.scrollLeft = scrollLeft.current - walk;
        }
    };

    return (<>
        <div style={{ margin: "30px 0px" }}>
            <div>
                <div style={{ display: "flex", width: "100%", borderBottom: "1px solid #d1d1d1", borderTop: "1px solid #d1d1d1", padding: "15px 0", justifyContent: "center", marginBottom: "10px" }}>
                    <div style={{ width: "150px" }}></div>
                    <h2 style={{ width: "100%", display: "flex", justifyContent: "center" }}>{t("Home.Packages")} </h2>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button bsPrefix="BrandShoppBrand" style={{ width: "150px", backgroundColor: "white" }} onClick={() => nav("/admin/market/getPackages")}>{t("Home.Show all")}</Button>
                    </div>
                </div>
                <div
                    ref={scrollRef}
                    onMouseDown={onMouseDown}
                    onMouseLeave={onMouseLeaveOrUp}
                    onMouseUp={onMouseLeaveOrUp}
                    onMouseMove={onMouseMove}
                    style={{ display: 'flex', width: "100%", gap: "2%", overflowX: "auto", scrollbarWidth: "none", cursor: "grab" }}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", gap: 20 }}>
                        {
                            dataProducts?.length! > 0 ? dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 10).map((Product, index) => (
                                <AdminCardPeoduct Product={Product} dragging={dragging} key={index} />
                            ))
                                :
                                <div style={{ width: "100%", display: 'flex', justifyContent: "center", minHeight: "50vh", alignItems: "center", fontSize: "22px" }}>
                                    {t("Market.There are no products with these specifications.")}
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div >
    </>)
}

export default PackagesAdmin;
