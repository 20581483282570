import { Button } from "react-bootstrap";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import { useState } from "react";
import Langu from "../../../components/languge/languge";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { decrementItems, incrementItems } from "../../../redux/counterSlice";
import { useNavigate } from "react-router";
import axios from "axios";

interface dataProductsProps {
    Product: Product;
    dragging: boolean;
}

const ClientCardPeoduct: React.FC<dataProductsProps> = ({ Product, dragging }) => {
    const { t } = useTranslation()
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const userData = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch()
    const nav = useNavigate()

    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null)
    const [colorActive, setColorActive] = useState<string>(Product?.colors?.[0]!)
    const [disableBtnRemind, setDisableBtnRemind] = useState<boolean>(false)

    const getquantityItem = (id: string, colorActive?: string) => {
        const item = CarentItem.cartItems.find((i) => ((i.data._id === id) && (i.color === colorActive)));
        if (item) {
            return item.quantity;
        } else {
            return 0;
        }
    }

    const onClickProduct = (event: React.MouseEvent) => {
        if ((event.target as HTMLElement).classList.contains("BtnBNB")) {
            return;
        }
        else if (!dragging) {
            nav(`/client/market/productDetails/${Product?._id}`)
        }
    };


    return (<>
        <div
            onMouseEnter={() => setHoveredItemId(Product._id!)}
            onMouseLeave={() => setHoveredItemId(null)}
        >
            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                <div className="cardHome" style={{ borderRadius: "5px" }} onClick={onClickProduct}>
                    <img draggable={false} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                    <img draggable={false} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                </div>
                {
                    Product.offers &&
                    <div style={{ position: "absolute", top: Product.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {Product.discount! * -1} %
                    </div>
                }
                {
                    Product.disable &&
                    <div style={{ position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                        {t("Market.Sold out")}
                    </div>
                }
                {Product.colors?.length! > 0 &&
                    <div style={{ display: "flex" }}>
                        {Product.colors?.map((color: string, index) => (
                            <Button
                                key={index}
                                onClick={() => setColorActive(color)}
                                style={{ backgroundColor: color, minWidth: 25, minHeight: 25, border: "1px solid #dddddd", margin: "10px 5px", boxShadow: colorActive == color ? `0px 0px 10px 5px ${colorActive}` : '' }}>
                            </Button>
                        ))}
                    </div>
                }
                {
                    hoveredItemId === Product._id && (
                        getquantityItem(Product?._id, colorActive) == 0 ?
                            <div className="BtnAddMarket" style={{ position: "absolute", top: "250px" }}>
                                <Button bsPrefix="BtnBNB" disabled={Product.disable} onClick={() => dispatch(incrementItems({ quantity: 1, data: Product, userId: userData.user.userDetals?._id!, color: colorActive }))} style={{ borderRadius: "20px" }}>{t("Market.add to cart")}</Button>
                            </div>
                            :
                            <div className="BtnAddMarket" style={{ position: "absolute", top: "250px", display: "flex", alignItems: "center", gap: "2px" }}>
                                <div>
                                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data: Product, userId: userData.user.userDetals?._id!, color: colorActive }))} disabled={getquantityItem(Product?._id!) === Product.number} style={{ borderRadius: "15px", width: "30px", height: "30px", padding: 0 }}>+</Button>
                                </div>
                                <div style={{ backgroundColor: "#842cff", color: "white", padding: "5px 10px", borderRadius: "15px" }}>
                                    {getquantityItem(Product?._id!, colorActive)} {t("Market.in cart")}
                                </div>
                                <div>
                                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data: Product, userId: userData.user.userDetals?._id!, color: colorActive }))} style={{ borderRadius: "15px", width: "30px", height: "30px", padding: 0 }}>-</Button>
                                </div>
                            </div>
                    )
                }
                <div style={{ fontSize: "20px", padding: "5px" }}><Langu {...Product.product!} /></div>
                <div style={{ width: "100%", fontSize: "15px" }}>
                    {
                        Product.newPrice ?
                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {Product.price} ₪
                                </div>
                                <div style={{ color: "red" }}>
                                    {Product.newPrice} ₪
                                </div>
                            </div>
                            :
                            <div style={{ textAlign: "center", color: "gray" }}>
                                {Product.price} ₪
                            </div>
                    }
                </div>
            </div>
        </div>
    </>)
}

export default ClientCardPeoduct;