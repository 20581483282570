import axios from "axios";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "react-bootstrap";
import { IoMdNotificationsOutline } from "react-icons/io";
import { OrderProps } from "../market/orders/getOrders/GetOrders.admin";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";



const NotificationsOrders: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData);
    const { t } = useTranslation()
    const nav = useNavigate()
    const [ordersINotifications, setOrdersINotifications] = useState<OrderProps[]>()

    useEffect(() => {
        const getOrders = setInterval(async () => {
            await axios.get(`${process.env.REACT_APP_URL_API}/API/Orders/GetOrdersINotifications`,
                {
                    headers: {
                        Authorization: "Bearer " + userData.user.token,
                    }
                }
            )
                .then((doc) => setOrdersINotifications(doc.data))
                .catch((err) => console.log(err))
        }, 2000)

        return () => clearInterval(getOrders)
    }, [])

    const filterDataNotifications = ordersINotifications?.filter((order) => order.status?.toLowerCase().includes("new"))

    const BtnNotifications = async () => {
        await axios.put(`${process.env.REACT_APP_URL_API}/API/Orders/PutStatusOrder`, { status: "new" },
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .catch((err) => console.log(err))
    }

    return (<>
        <div style={{ position: "relative" }}>
            <DropdownButton
                bsPrefix="custom-dropdown-button"
                title={
                    <IoMdNotificationsOutline size={"30px"} className="BtnBNBIcon" style={{ fontWeight: "900" }} />
                }
                onClick={BtnNotifications}
            >
                {
                    ordersINotifications?.map((order, index) => (
                        <Dropdown.Item key={index} style={{ maxWidth: "100%" }} onClick={() => nav(`/admin/market/orders/getOrder/${order._id}`)}>
                            <div style={{ maxWidth: "10%", wordWrap: "break-word", overflowWrap: "break-word" }}>
                                <div style={{ display: "flex" }}>
                                    <div>
                                        {t("Hedar.Notifications.Order from")} {order.userName}
                                    </div> ,
                                    <div>
                                        {t("Hedar.Notifications.status")} {t(`Market.PayPage.${order.status}`)}
                                    </div>
                                </div>
                                <div style={{ fontSize: '13px' }}>
                                    {moment(order.createdAt).fromNow()}
                                </div>
                            </div>
                        </Dropdown.Item>
                    ))
                }
                <Dropdown.Divider />
                <Dropdown.Item style={{ textAlign: "start" }} onClick={() => nav('/admin/market/orders/getOrders')}>{t("Hedar.Notifications.All orders")}</Dropdown.Item>
            </DropdownButton>
            {
                filterDataNotifications?.length! > 0 &&
                <div style={{ position: "absolute", top: "-5px", left: "-5px", width: "20px", height: "20px", backgroundColor: "red", borderRadius: "50%", color: "white", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {filterDataNotifications?.length}
                </div>
            }
        </div>
    </>)
}

export default NotificationsOrders;