import { useState } from "react";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import Langu from "../../../components/languge/languge";
import ModelXLAdmin from "../../market/Modales/ModaleXL.admin";
import { useTranslation } from "react-i18next";

interface CardProductProp {
    dataProducts: Product[];
}

const AdminYouMayAlsoLike: React.FC<CardProductProp> = ({ dataProducts }) => {
    const { t } = useTranslation()
    const [showModalXL, setShowModalXL] = useState<any>({
        setShow: false,
        data: {}
    });

    return (<>
        <div style={{ borderBottom: "1px solid #dddddd" }}>
            {
                dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 3).map((Product, index) => (
                    <div
                        key={index}
                        onClick={() => setShowModalXL({ setShow: true, data: Product })}
                        style={{ margin: "10px 0" }}
                    >
                        <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: "row", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                            <div className="cardYouMayAlsoLike" style={{ borderRadius: "5px" }}>
                                <img draggable={false} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                                <img draggable={false} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                            </div>
                            {
                                Product.offers &&
                                <div style={{ position: "absolute", top: "10px", left: Product.disable ? "90px" : "0px", backgroundColor: "red", borderRadius: "15px", padding: "10px", color: "white", width: "50px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", fontSize: "10px" }}>
                                    {Product.discount! * -1} %
                                </div>
                            }
                            {
                                Product.disable &&
                                <div style={{ position: "absolute", top: "10px", left: "0", backgroundColor: "black", borderRadius: "15px", padding: "10px", color: "white", width: "80px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", fontSize: "10px" }}>
                                    {t("Market.Sold out")}
                                </div>
                            }
                            <div>
                                <div style={{ fontSize: "15px", padding: "5px" }}><Langu {...Product.product!} /></div>
                                <div style={{ width: "100%", fontSize: "12px" }}>
                                    {
                                        Product.newPrice ?
                                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                                    {Product.price} ₪
                                                </div>
                                                <div style={{ color: "red" }}>
                                                    {Product.newPrice} ₪
                                                </div>
                                            </div>
                                            :
                                            <div style={{ textAlign: "center", color: "gray" }}>
                                                {Product.price} ₪
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
        {
            showModalXL.setShow &&
            <ModelXLAdmin show={showModalXL.setShow} setShowModalXL={setShowModalXL} data={showModalXL.data} />
        }
    </>)
}

export default AdminYouMayAlsoLike;