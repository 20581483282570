import { Button } from "react-bootstrap";
import CategorysClient from "./Categorye.client";
import NewProductsClient from "./NewProducts.client";
import Offer1Client from "./Offer1.client";
import Product1Client from "./Product1.client";
import img from '../../files/HomeGuest/ضصثضصيسش.jpg'
import PackagesClient from "./Packages.client";
import MostCommonClient from "./MostCommon.client";
import ShoppBrandClient from "./ShoppBrand.client";



const HomeClient: React.FC = () => {
    return (<>
        <div>
            <div>
                <Offer1Client />
            </div>
            <div>
                <CategorysClient />
            </div>
            <div>
                <Product1Client />
            </div>
            <div>
                <NewProductsClient />
            </div>
            {/* <div>
                <div
                    style={{
                        minHeight: "100vh", width: "100%", backgroundImage: `url(${img})`, backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        display: "flex", justifyContent: "center", alignItems: "center"
                    }}
                >
                    <Button bsPrefix="BtnBNB">تواصل معنا على الواتس أب</Button>
                </div>
            </div> */}
            <div>
                <PackagesClient />
            </div>
            <div>
                <MostCommonClient />
            </div>
            <div>
                <ShoppBrandClient />
            </div>
        </div>
    </>)
}

export default HomeClient;