import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import { TbShoppingCartX } from "react-icons/tb";
import CartItemPayPage from "../../../components/cartItem/CartItemPayPage";
import { useLocation, useNavigate } from "react-router";



const PayPage: React.FC = () => {
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const { t } = useTranslation()
    const nav = useNavigate()

    const subTotal: number = CarentItem.cartItems.reduce((total, cartitem) => {
        return total + (cartitem.data.newPrice || cartitem.data.price || 0) * cartitem.quantity
    }, 0)

    return (<>
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginBottom: "50px" }}>
            <div style={{ width: "100%", borderBottom: "1px solid #dddddd", height: "10vh", backgroundColor: "gray", display: "flex", justifyContent: "center", alignItems: "center", color: "white" }}>
                <h2>
                    {t("Market.PayPage.shopping cart")}
                </h2>
            </div>
            <div style={{ maxWidth: "1440px", width: "100%" }}>
                {
                    CarentItem.cartItems.length > 0 ?
                        <>
                            <div style={{ width: "100%" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", borderBottom: "2px solid black", fontSize: "20px", padding: "50px 0 10px 0", marginBottom: "20px" }}>
                                    <div style={{ width: "40%" }}>
                                        {t("Market.product")}
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        {t("Market.price")}
                                    </div>
                                    <div style={{ width: "20%", textAlign: "center" }}>
                                        {t("Market.Quantity")}
                                    </div>
                                    <div style={{ width: "20%", textAlign: "end" }}>
                                        {t("Market.Total price")}
                                    </div>
                                </div>
                                <div style={{ width: "100%" }}>
                                    {CarentItem?.cartItems.map((item: any ,index) => { return item ? <CartItemPayPage key={index} data={item} /> : null }
                                    )}
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "5vh" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                        <div style={{ fontSize: "20px", fontWeight: "500" }}>
                                            {t("Market.PayPage.Discount coupon")} :
                                        </div>
                                        <div>
                                            <Form.Control type="text" placeholder={t("Market.PayPage.Discount code")} />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                                        <div style={{ fontSize: "20px", fontWeight: "500" }}>
                                            {t("Market.PayPage.Subtotal")} : {subTotal} ₪
                                        </div>
                                        <div>
                                            <Button bsPrefix="BtnBNB" onClick={() => nav("/client/market/ompleteOrder")}> {t("Market.PayPage.Complete the order")}</Button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                            <div style={{ display: "flex", flexDirection: 'column', alignItems: "center" }}>
                                <TbShoppingCartX size={"100px"} />
                                <h1>{t("Market.PayPage.Your shopping cart is empty")}</h1>
                            </div>
                        </>
                }
            </div>
        </div>
    </>)
}

export default PayPage;