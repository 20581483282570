import "./Home.css"
import Offer1Guest from "./Offer1.guest"
import CategorysGuest from "./Categorye.guest"
import Product1Guest from "./Product1.guest"
import NewProductsGuest from "./NewProducts.guest"
import PackagesGuest from "./Packages.guest"
import MostCommonGuest from "./MostCommon.guest"
import ShoppBrandGuest from "./ShoppBrand.guest"


const Home: React.FC = () => {
    return (<>
        <div>
            <div>
                <div>
                    <Offer1Guest />
                </div>
                <div>
                    <CategorysGuest />
                </div>
                <div>
                    <Product1Guest />
                </div>
                <div>
                    <NewProductsGuest />
                </div>
                <div>
                    <PackagesGuest />
                </div>
                <div>
                    <MostCommonGuest />
                </div>
                <div>
                    <ShoppBrandGuest />
                </div>
            </div>
        </div>
    </>)
}

export default Home;