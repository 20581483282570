import axios from "axios";
import { useEffect, useState } from "react";
import { Brand, Product } from "../../admin/homeAdmin/HomeAdmin";
import Langu from "../../components/languge/languge";
import { Alert, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";


const ShoppBrandGuest: React.FC = () => {

    const { t } = useTranslation()
    const [dataCategorys, setDataCategorys] = useState<Brand[]>()
    const [dataProducts, setDataProducts] = useState<Product[]>()
    const [dataBrand, setDataBrand] = useState<Brand | undefined>(dataCategorys ? dataCategorys[0] : undefined);
    const [hoveredItemId, setHoveredItemId] = useState<string | null>(null)
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
    const [showModalXL, setShowModalXL] = useState<any>({
        setShow: false,
        data: {}
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Brands/GetBrands`
        )
            .then((doc) => setDataCategorys(doc.data))
            .catch((err) => console.log(err))
    }, [])

    useEffect(() => {
        setDataBrand(dataCategorys ? dataCategorys[0] : undefined)
    }, [dataCategorys])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProductsBrand/${dataBrand?.name.en}`)
            .then((doc) => setDataProducts(doc.data))
            .catch((err) => console.log(err))
    }, [dataBrand])

    const onClickProduct = (event: React.MouseEvent, product: Product) => {
        if ((event.target as HTMLElement).classList.contains("BtnBNB")) {
            return;
        }
        setShowModalXL({ setShow: true, data: product });
    };

    return (<>
        <div style={{ margin: ' 0 0 30px 0' }}>
            <div>
                <h2 style={{ width: "100%", display: "flex", justifyContent: "center", borderBottom: "1px solid #d1d1d1", borderTop: "1px solid #d1d1d1", padding: "15px 0" }}>{t("Home.Shop by brand")}</h2>
            </div>
            <div style={{ display: "flex", gap: "30px", justifyContent: "center" }}>
                {
                    dataCategorys?.map((brand, index) => <div key={index}>
                        <div style={{ display: "flex", justifyContent: "space-evenly", minHeight: "50px", gap: "30px" }}>
                            {
                                brand === dataBrand ?
                                    <Button key={index} bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white", border: "1px solid #6f24d7", color: "#6f24d7" }} onClick={() => setDataBrand(brand)}><Langu {...brand.name} /></Button>
                                    :
                                    <Button key={index} bsPrefix="BrandShoppBrand" style={{ backgroundColor: "white" }} onClick={() => setDataBrand(brand)}><Langu {...brand.name} /></Button>
                            }
                        </div>
                    </div>
                    )
                }
            </div>
            <div style={{ display: "flex", gap: "20px", marginTop: "30px", padding: "0 40px", flexWrap: "wrap", justifyContent: "center" }}>
                {
                    dataProducts?.length != 0 ? dataProducts?.sort((a, b) => new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()).slice(0, 10).map((Product, index) => (
                        <div
                            key={index}
                            onClick={(event) => onClickProduct(event, Product)}
                            onMouseEnter={() => setHoveredItemId(Product._id!)}
                            onMouseLeave={() => setHoveredItemId(null)}
                        >
                            <div style={{ display: 'flex', justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%", cursor: "pointer", position: "relative" }}>
                                <div className="cardHome" style={{ borderRadius: "5px" }}>
                                    <img draggable={false} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[0] : ""}`} />
                                    <img draggable={false} className="imgHomeHover2" src={`${process.env.REACT_APP_URL_API}/API/files/Products/${Product.file ? Product.file[1] : ""}`} />
                                </div>
                                {
                                    Product.offers &&
                                    <div style={{ position: "absolute", top: "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        {Product.discount! * -1} %
                                    </div>
                                }
                                {
                                    hoveredItemId === Product._id &&
                                    <div className="BtnAddMarket" style={{ position: "absolute", top: "250px" }}>
                                        <Button bsPrefix="BtnBNB" onClick={() => { setShowAlirtMsg(true); setShowModalXL({ setShow: false }) }} style={{ borderRadius: "20px" }}>{t("Market.add to cart")}</Button>
                                    </div>
                                }
                                <div style={{ fontSize: "20px", padding: "5px" }}><Langu {...Product.product!} /></div>
                                <div style={{ width: "100%", fontSize: "15px" }}>
                                    {
                                        Product.newPrice ?
                                            <div style={{ display: "flex", width: "100%", justifyContent: "center", gap: '20px' }}>
                                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                                    {Product.price} ₪
                                                </div>
                                                <div style={{ color: "red" }}>
                                                    {Product.newPrice} ₪
                                                </div>
                                            </div>
                                            :
                                            <div style={{ textAlign: "center", color: "gray" }}>
                                                {Product.price} ₪
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))
                        :
                        <div style={{ margin: "170px 0", color: "red" }}>
                            {t("Market.There are no products for this brand.")}
                        </div>
                }
            </div>
            <Alert show={showAlirtMsg} variant={"danger"} style={{ position: "fixed", top: '10px', left: "30px", minWidth: "20%", zIndex: "10" }} onClose={() => setShowAlirtMsg(false)} dismissible>
                <Alert.Heading style={{ textAlign: "start" }}>
                    {t("Market.Error message")}
                </Alert.Heading>
                <p>
                    {t("Market.You are not registered, please log in to continue")}
                </p>
            </Alert>
        </div>
    </>)
}
export default ShoppBrandGuest;