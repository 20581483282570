import { Outlet } from "react-router-dom";
import HedarAdmin from "../hedarAdmin/HedarAdmin";
import { motion } from 'framer-motion';
import FloatingButtonAdmin from "../components/supportIcon/SupportIconClient";


const pageTransition = {
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: "-100%" }
};

const OutletAdmin: React.FC = () => {
    return (<>
        <HedarAdmin />
        <motion.div
            initial="out"
            animate="in"
            exit="out"
            variants={pageTransition}
            transition={{ duration: 0.1 }}
        >
            <Outlet />
            <FloatingButtonAdmin />
        </motion.div>
    </>)
}

export default OutletAdmin;