import { useEffect, useState } from "react";
import { UsersProps } from "../homeAdmin/HomeAdmin";
import axios from "axios";
import { Button, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";




const Dashboard: React.FC = () => {
    const userData = useSelector((state: RootState) => state.userData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const [dataUsers, setDataUsers] = useState<UsersProps[]>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Users/GetUsers`,
            {
                headers: {
                    Authorization: "Bearer " + userData.user.token,
                }
            }
        )
            .then((doc) => setDataUsers(doc.data))
            .catch((err) => console.log(err))
    }, [])



    return (<>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ maxWidth: "1440px", width: "100%", padding: "0 40px" }}>
                <Table striped>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t("Home.name")}</th>
                            <th>{t("Home.phone")}</th>
                            <th>{t("Home.email")}</th>
                            <th>{t("Home.role")}</th>
                            <th>{t("Home.active")}</th>
                            <th style={{ width: "15%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataUsers?.map((user, index) =>
                                <tr key={user._id}>
                                    <td>{index + 1}</td>
                                    <td>{user.userName}</td>
                                    <td>{user.phone}</td>
                                    <td>{user.email}</td>
                                    <td>{user?.role?.toString()}</td>
                                    <td>{user?.active?.toString()}</td>
                                    <td style={{ width: "15%" }}><Button bsPrefix="BtnBNB" onClick={() => nav(`/admin/dashboard/userId/${user._id}`)}>{t("Market.Order details")}</Button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    </>)
}
export default Dashboard;