import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Loading } from "../../../components/laoding/laoding";
import { ThemeColor } from "../../../theme";
import { useTranslation } from "react-i18next";
import InfoProduct from "./InfoProduct";
import { motion } from "framer-motion";
import { ChatContext } from "../../../components/context/ChatContext";
import { Product } from "../../../admin/homeAdmin/HomeAdmin";
import YouMayAlsoLikeGuest from "../YouMayAlsoLikeGuest";



const ProductDetails = () => {
    const { t } = useTranslation()
    const params = useParams()
    const [dataProduct, setDataProduct] = useState<Product>()
    const [photoActive, setPhotoActive] = useState<string>(dataProduct?.file?.[0]!)

    const context = useContext(ChatContext)
    const { isMobile } = context!

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${params.id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
    }, [params.id])

    useEffect(() => {
        setPhotoActive(dataProduct?.file?.[0]!)
    }, [dataProduct])


    return (<>
        {dataProduct ?
            <div style={isMobile ? { display: "flex", justifyContent: "center", margin: "10vh 0", flexDirection: "column" } : { display: "flex", justifyContent: "center", margin: "10vh 0" }}>
                <div style={isMobile ? { width: "100%", maxWidth: 1660, display: "flex", gap: 20, flexDirection: "column" } : { width: "100%", maxWidth: 1660, display: "flex", gap: 20 }}>
                    <div style={isMobile ? { maxWidth: 600, display: "flex", flexDirection: "column" } : { maxWidth: 600, display: "flex" }}>
                        <div style={isMobile ? { display: "flex", justifyContent: "center" } : { display: "flex", flexDirection: "column" }}>
                            {dataProduct?.file?.[0] &&
                                <img draggable={false}
                                    onClick={() => isMobile ? setPhotoActive(dataProduct?.file?.[0] ?? '') : undefined}
                                    onMouseEnter={() => {
                                        if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                    }}
                                    onMouseLeave={() => {
                                        if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                    }}
                                    style={{ width: 100, height: 100, border: photoActive === dataProduct?.file?.[0] ? `2px ${ThemeColor.bgColor(1)} solid` : "none", padding: 3 }}
                                    src={`${process.env.REACT_APP_URL_API}/API/files/Products/${dataProduct?.file?.[0] ?? ""}`} />
                            }
                            {dataProduct?.file?.[1] &&
                                <img draggable={false}
                                    onClick={() => isMobile ? setPhotoActive(dataProduct?.file?.[1] ?? '') : undefined}
                                    onMouseEnter={() => {
                                        if (!isMobile) setPhotoActive(dataProduct?.file?.[1] ?? '');
                                    }}
                                    onMouseLeave={() => {
                                        if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                    }}
                                    style={{ width: 100, height: 100, border: photoActive === dataProduct?.file?.[1] ? `2px ${ThemeColor.bgColor(1)} solid` : "none", padding: 3 }}
                                    src={`${process.env.REACT_APP_URL_API}/API/files/Products/${dataProduct?.file?.[1] ?? ""}`} />
                            }
                            {dataProduct?.file?.[2] &&
                                <img draggable={false}
                                    onClick={() => isMobile ? setPhotoActive(dataProduct?.file?.[2] ?? '') : undefined}
                                    onMouseEnter={() => {
                                        if (!isMobile) setPhotoActive(dataProduct?.file?.[2] ?? '');
                                    }}
                                    onMouseLeave={() => {
                                        if (!isMobile) setPhotoActive(dataProduct?.file?.[0] ?? '');
                                    }}
                                    style={{ width: 100, height: 100, border: photoActive === dataProduct?.file?.[2] ? `2px ${ThemeColor.bgColor(1)} solid` : "none", padding: 3 }}
                                    src={`${process.env.REACT_APP_URL_API}/API/files/Products/${dataProduct?.file?.[2] ?? ""}`} />
                            }
                        </div>
                        <div style={{ position: "relative" }}>
                            <motion.img
                                key={photoActive} // هذا المفتاح يجبر Framer Motion على إعادة تشغيل الأنيميشن عند تغيير الصورة
                                draggable={false}
                                initial={{ opacity: 0, scale: 0.9 }} // القيمة الابتدائية
                                animate={{ opacity: 1, scale: 1 }} // القيمة النهائية
                                exit={{ opacity: 0, scale: 0.9 }} // القيمة عند الخروج
                                transition={{ duration: 0.5 }} // مدة الأنيميشن
                                style={{
                                    width: isMobile ? "100%" : 498,
                                    height: isMobile ? "100%" : 490,
                                    animation: " 1s",
                                    transition: "ease 1s",
                                }}
                                src={`${process.env.REACT_APP_URL_API}/API/files/Products/${photoActive ?? ""}`}
                            />
                            {
                                dataProduct.offers &&
                                <div style={{ position: "absolute", top: dataProduct.disable ? "90px" : "20px", left: "20px", backgroundColor: "red", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    {dataProduct.discount! * -1} %
                                </div>
                            }
                            {
                                dataProduct.disable &&
                                <div style={{ position: "absolute", top: "20px", left: "20px", backgroundColor: "black", borderRadius: "50%", padding: "10px", color: "white", width: "65px", height: "65px", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                    {t("Market.Sold out")}
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{ maxWidth: 600, width: "100%" }}>
                        <InfoProduct data={dataProduct} />
                    </div>
                    <div style={{ maxWidth: 460, width: "100%" }}>
                        <div style={{ textAlign: 'center', fontWeight: 700, fontSize: 20 }}>{t("Home.You may also like")}</div>
                        <YouMayAlsoLikeGuest />
                    </div>
                </div>
            </div>
            :
            <Loading />
        }
    </>)
}

export default ProductDetails;