import { Button, Stack } from "react-bootstrap";
import Langu from "../languge/languge";
import { decrementItems, incrementItems } from "../../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useEffect, useState } from "react";
import axios from "axios";
import { Product } from "../../admin/homeAdmin/HomeAdmin";


interface CartItemPayPageProps {
    data: {
        data: Product;
        quantity: number;
        color: string;
    }
}


const CartItemPayPage: React.FC<CartItemPayPageProps> = ({ data }) => {
    const dispatch = useDispatch()
    const userData = useSelector((state: RootState) => state.userData);
    const [dataProduct, setDataProduct] = useState<Product>()

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_URL_API}/API/Products/GetProduct/${data.data._id}`)
            .then((doc) => setDataProduct(doc.data))
            .catch((err) => console.log(err))
    }, [])

    return (<>
        <Stack style={{ display: "flex", maxHeight: "200px", flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #dddddd", padding: "15px 0" }}>
            <div style={{ display: "flex", width: "40%", alignItems: "center", gap: "20px" }}>
                <img style={{ height: "160px", width: "200px", borderRadius: "8px" }} src={`${process.env.REACT_APP_URL_API}/API/files/Products/${data?.data?.file?.[0]}`} alt={data.data.product?.en} />
                <div>
                    <div style={{ fontWeight: "500", fontSize: "15px" }}>
                        {Langu(data?.data?.product!)}
                    </div>
                </div>
                {data.color &&
                    <div style={{ width: '50px', textAlign: "center" }}>
                        <div style={{ minWidth: 20, maxWidth: 20, minHeight: 20, maxHeight: 20, backgroundColor: data.color }}></div>
                    </div>
                }
            </div>
            <div style={{ width: "20%", textAlign: "center", fontWeight: "500", fontSize: "15px" }}>
                {
                    data.data.newPrice ?
                        <>
                            <div>
                                <div style={{ textDecoration: "line-through", color: "gray" }}>
                                    {data.data.price} ₪
                                </div>
                                <div style={{ color: "red" }}>
                                    {data.data.newPrice} ₪
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {data.data.price} ₪
                        </>
                }
            </div>
            <div style={{ display: "flex", width: "20%", justifyContent: "center", gap: "10px" }}>
                <div>
                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(incrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} disabled={data.quantity == dataProduct?.number} style={{ width: "30px", height: "30px", padding: "0" }}>+</Button>
                </div>
                <div style={{ width: "20px", textAlign: "center", fontWeight: "500", fontSize: "15px" }}>
                    {data.quantity}
                </div>
                <div>
                    <Button bsPrefix="BtnBNB" onClick={() => dispatch(decrementItems({ quantity: 1, data: data.data, userId: userData.user.userDetals?._id!, color: data.color }))} style={{ width: "30px", height: "30px", padding: "0" }}>-</Button>
                </div>
            </div>
            <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-evenly", width: "20%" }}>
                {
                    data.data.newPrice ?
                        <div style={{ textAlign: "end", fontWeight: "500", fontSize: "15px" }}>
                            <div style={{ textDecoration: "line-through", color: "gray" }}>
                                {data.data.price! * data.quantity} ₪
                            </div>
                            <div style={{ color: "red" }}>
                                {data.data.newPrice * data.quantity} ₪
                            </div>
                        </div>
                        :
                        <div style={{ textAlign: "end", fontWeight: "500", fontSize: "15px" }}>
                            {data.data.price! * data.quantity} ₪
                        </div>
                }
            </div>
        </Stack >
    </>)
}


export default CartItemPayPage;