import { Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import '../../guest/hedarGuest/HedarGuest.css'
import { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import Search from "../../guest/hedarGuest/Search";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import Cookies from "universal-cookie";
import { isExpired } from "react-jwt";
import axios from "axios";
import { setUser } from "../../redux/userSlice";
import I18nClient from "./I18n.client";
import { useTranslation } from "react-i18next";
import AccuntDropdownClient from "./AccuntDropdown.client";
import { MdOutlineShoppingCart } from "react-icons/md";
import ShoppingCart from "../../guest/hedarGuest/ShoppingCart";
import { GrFavorite } from "react-icons/gr";



const HedarClient: React.FC = () => {

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);
    const CarentItem = useSelector((state: RootState) => state.counterData)
    const { t } = useTranslation()
    const nav = useNavigate()
    const cookie = new Cookies()
    const getTokenCookie = cookie.get("bearer");

    const [showShoppingCart, setShowShoppingCart] = useState<boolean>(false)
    const [showSearch, setShowSearch] = useState<boolean>(false)

    let TokenReducer = userData?.user?.token
    let IsExpiredReducerToken = isExpired(TokenReducer!)
    let RefreshToken = getTokenCookie?.refreshToken

    const quantitys = CarentItem?.cartItems.reduce((total, cartitem) => {
        return total + cartitem.quantity
    }, 0)


    useEffect(() => {
        if (IsExpiredReducerToken) {
            axios.get(`${process.env.REACT_APP_URL_API}/API/Auth/RefreshToken`, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + RefreshToken,
                }
            })
                .then((doc) => {
                    const token = doc.data.token;
                    const userDetals = doc.data.data
                    cookie.set("bearer", { token: token, refreshToken: RefreshToken, userDetals: userDetals }, { path: "/", expires: new Date(Date.now() + 2 * 30 * 24 * 60 * 60 * 1000) });
                    dispatch(setUser({ token: token, refreshToken: RefreshToken, userDetals: userDetals }))
                    console.log("ok refresh")
                })
                .catch((err) => {
                    console.log("err refreshPage : ", err);
                })
        }
    }, [IsExpiredReducerToken, RefreshToken, TokenReducer, dispatch, cookie])

    return (<>
        <nav>
            <div style={{ minHeight: "65px" }}>
                <div className="NavHedar">
                    <div style={{ maxWidth: "1440px", width: "1440px", padding: "0 40px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                    {/* <Notification /> */}
                                    <NavLink className={"NavLinkHedar"} to="/client/homePage">{t("Home.home")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/client/market/category/Accessories and shawls">{t("Hedar.Accessories and shawls")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/client/market/category/bags" >{t("Hedar.bags")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/client/market/category/hours" >{t("Hedar.hours")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/client/market/category/wallets" >{t("Hedar.wallets")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/client/market/category/Hats and glasses">{t("Hedar.Hats and glasses")}</NavLink>
                                    {/* <NavLink className={"NavLinkHedar"} to="/client/Chat">Chat</NavLink> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' style={{ display: "flex" }} onClick={() => setShowShoppingCart(true)}>
                                        <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white" }}>{quantitys}</div>
                                        <MdOutlineShoppingCart className='IconHedar' size={"25px"} />
                                    </Button>
                                </div>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' style={{ display: "flex", gap: "2px" }} onClick={() => { nav(`/client/market/Favorite/${userData.user?.userDetals?._id}`) }}>
                                        <div style={{ backgroundColor: "#842cff", minWidth: "24px", borderRadius: "50%", color: "white", }}>{userData.user.userDetals?.favorite?.length}</div>
                                        <GrFavorite className='IconHedar' size={"25px"} />
                                    </Button>
                                </div>
                                <div>
                                    <AccuntDropdownClient />
                                </div>
                                <div>
                                    <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                </div>
                                <div>
                                    <I18nClient />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Search show={showSearch} setShowSearch={setShowSearch} />
            <ShoppingCart show={showShoppingCart} setShowShoppingCart={setShowShoppingCart} />
        </nav>
    </>)
}

export default HedarClient;