import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import '../../guest/hedarGuest/HedarGuest.css'
import { useState } from "react";
import { IoSearch } from "react-icons/io5";
import Search from "../../guest/hedarGuest/Search";
import I18nAdmin from "./I18n.admin";
import { useTranslation } from "react-i18next";
import AccuntDropdownAdmin from "./AccuntDropdown.admin";
import NotificationsOrders from "./NotificationsOrders";


const HedarAdmin: React.FC = () => {
    const { t } = useTranslation()
    const [showSearch, setShowSearch] = useState<boolean>(false)

    return (<>
        <nav>
            <div style={{ minHeight: "65px" }}>
                <div className="NavHedar">
                    <div style={{ maxWidth: "1440px", width: "1440px", padding: "0 40px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <div className='MenuNavLink' style={{ display: "flex", gap: "20px" }}>
                                    <NavLink className={"NavLinkHedar"} to="/admin/homePage">{t("Home.home")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/dashboard">{t("Home.dashboard")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/createCategory">{t("Home.create Category")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/createProduct">{t("Home.create Product")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/createOffer">{t("Home.create Offer")}</NavLink>
                                    <NavLink className={"NavLinkHedar"} to="/admin/market/orders/getOrders">{t("Home.Orders")}</NavLink>
                                    {/* <NavLink className={"NavLinkHedar"} to="/admin/chat">Chat</NavLink> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <div>
                                    <NotificationsOrders />
                                </div>
                                <div>
                                    <AccuntDropdownAdmin />
                                </div>

                                <div>
                                    <Button bsPrefix='BtnBNBIcon' onClick={() => setShowSearch(true)}><IoSearch size={"25px"} /></Button>
                                </div>
                                <div>
                                    <I18nAdmin />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Search show={showSearch} setShowSearch={setShowSearch} />
        </nav>
    </>)
}

export default HedarAdmin;