import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { BiSupport } from "react-icons/bi";
import "./SupportIconClient.css"
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SiWhatsapp } from "react-icons/si";
import { IoIosChatbubbles } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useNavigate } from 'react-router';
import { ChatContext } from '../../../components/context/ChatContext';
import { UnreadNotification } from '../../../components/Chat/UnradeNotification';

const FloatingButtonAdmin: React.FC = () => {
    const { t } = useTranslation()
    const nav = useNavigate()
    const chatContext = useContext(ChatContext);

    const { notification, notificationDB } = chatContext!
    const unreadNotification = UnreadNotification({ notificationDB })

    useEffect(() => {

    }, [])

    const [isOpen, setIsOpen] = useState(false);
    // const [isOpen2, setIsOpen2] = useState(false);
    const [showAlirtMsg, setShowAlirtMsg] = useState<boolean>(false)
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="floating-container">
            {notificationDB?.length > 0 && <div style={{ position: "absolute", width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "red", color: "white", fontSize: "15px", bottom: "40px", left: "-10px", zIndex: 3, textAlign: "center" }}>  <span>{notificationDB?.length!}</span></div>}
            <div className={`icon-container ${isOpen ? 'open' : ''}`}>
                <Button onClick={() => setShowAlirtMsg(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '8px' }}>
                    <SiWhatsapp color='#00a884' />
                </Button>
                <Button onClick={() => nav("/admin/Chat")} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '6px', position: "relative" }}>
                    {notificationDB?.length > 0 && <div style={{ position: "absolute", width: "20px", height: "20px", borderRadius: "50%", backgroundColor: "red", color: "white", fontSize: "15px", top: "-10px", left: "-10px" }}>  <span>{notificationDB?.length!}</span></div>}
                    <IoIosChatbubbles color='#6f24d7' />
                </Button>
                <Button onClick={() => setShowAlirtMsg(true)} variant="light" className="floating-icon" style={{ fontSize: "30px", padding: '10px' }}>
                    <BsFillTelephoneFill color='#008069' />
                </Button>
            </div>

            <Button onClick={toggleMenu} bsPrefix='BtnBNB' style={{ position: 'fixed', bottom: '30px', right: '30px', padding: "0 10px", borderRadius: "50%", height: "50px" }}>
                <BiSupport size={"30px"} />
            </Button>
            <Alert show={showAlirtMsg} variant={"danger"} style={{ position: "fixed", top: '80px', left: "30px", minWidth: "20%", zIndex: "10" }} onClose={() => setShowAlirtMsg(false)} dismissible>
                <Alert.Heading style={{ textAlign: "start" }}>
                    {t("Market.Error message")}
                </Alert.Heading>
                <p>
                    {t("Market.You are not registered, please log in to continue")}
                </p>
            </Alert>
        </div>
    );
}


export default FloatingButtonAdmin;